import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
import { BasicContext } from "../../../App";
import BarmanRolesDropdown from "../../forms/BarmanRolesDropdown";
import Phone from "../../forms/Phone";
import TextareaForm from "../../forms/TextareaForm";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";
import ConfirmActionModal from "../../modals/ConfirmActionModal";

const EditBarman = () => {
    const { setBasicAlert, QRscanner, setQRscanner, QRData, fetchURL, setQRData } = useContext(BasicContext);
    const { currentPage, loggedUserQR, setNextPage } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [barmanData, setBarmanData] = useState(null);
    const [fetchEditBarman, setFetchEditBarman] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        const checkIdentAssignment = async () => {
            const passData = {
                value: QRData,
            };
            try {
                const response = await fetch(`${fetchURL}/check-assigned-ident`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission" || fetchedData.status === "assigned") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else if (!barmanData.user_qr_code) setBarmanData({ ...barmanData, user_qr_code: QRData });
                        else setBarmanData({ ...barmanData, newIdent: QRData });
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };

        if (QRData) checkIdentAssignment();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
    }, [QRData]);

    useEffect(() => {
        const getBarmanData = async () => {
            setBasicAlert(null);
            try {
                const response = await fetch(`${fetchURL}/get-barman-data`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userID: currentPage.params.userID,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);

                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            const barmanData = fetchedData.barmanData;
                            setBarmanData(barmanData);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        getBarmanData();
    }, []);

    useEffect(() => {
        const editBarman = async () => {
            setBasicAlert(null);
            try {
                const response = await fetch(`${fetchURL}/edit-barman`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userID: currentPage.params.userID,
                        barmanData: barmanData,
                        loggedUserQR: loggedUserQR,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);

                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.barmanEdited`), fading: 2000 });
                            setNextPage({ nav: "barmans", subpage: "barmansList", params: "" });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchEditBarman) editBarman();

        return () => {
            setFetchEditBarman(false);
        };
    }, [fetchEditBarman]);

    const handleCheckValidation = () => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });

        const errorMsg = [];

        if (!barmanData.user_qr_code && !barmanData.newIdent) {
            errorMsg.push(t("error.noScannedQRCode"));
        }
        if (!barmanData.user_phone) {
            errorMsg.push(t("error.emptyPhone"));
            document.getElementById("userPhone").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.editingBarman") });
            setFetchEditBarman(true);
        }
    };

    const handleCheckRoleActivity = () => {
        let allRolesInactive = true;
        barmanData.userRoles.forEach((e) => {
            //console.log(e);
            if (e.u2r_active) allRolesInactive = false;
        });

        if (allRolesInactive) setShowConfirmModal(true);
        else handleCheckValidation();
    };

    return (
        <Fragment>
            <NavDetails backBtn title={`${currentPage.nav}.${currentPage.subpage}`} />
            {showConfirmModal && <ConfirmActionModal onClose={() => setShowConfirmModal(false)} modalTxt={t("barmans.confirmInactiveRoles")} onProceed={handleCheckValidation} />}
            <Form autoComplete="off">
                <div className="scrolled-content">
                    <Container className="pt-3">
                        {loading ? (
                            <Row>
                                <Col>
                                    <LoadingProgressBar />
                                </Col>
                            </Row>
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col xs={12} className="text-center mt-2 mb-4">
                                        <p className="h4">
                                            {t("barmans.editBarmanData")} <b>{barmanData.user_fullname}</b>
                                        </p>
                                    </Col>
                                    <Col xs={12} lg={4} md={6}>
                                        <Form.Label>
                                            {t("ident.assignCode")} <span className="required">*</span>
                                        </Form.Label>
                                        {barmanData.user_qr_code || barmanData.newIdent ? <p>{t("ident.identScanned")}</p> : null}
                                        <Button
                                            variant="outline-primary"
                                            size="lg"
                                            className="d-block mb-3 w-100"
                                            onClick={() => {
                                                setDisableSubmitting(false);
                                                setBasicAlert(null);
                                                setQRscanner({ ...QRscanner, show: true });
                                            }}
                                        >
                                            {barmanData.user_qr_code ? t("ident.scanNewCode") : t("ident.scanQRCode")}
                                        </Button>
                                    </Col>
                                    <Col xs={12} lg={4} md={6}>
                                        <Phone
                                            value={barmanData.user_phone}
                                            onChangeValue={(e) => {
                                                setDisableSubmitting(false);
                                                setBarmanData({ ...barmanData, user_phone: e.target.value });
                                            }}
                                            name="user_phone"
                                            id="userPhone"
                                            req
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <Form.Label>{t("users.role")}</Form.Label>
                                    </Col>
                                    <Col xs={3} md={3}>
                                        <Form.Label>{t("user.roleDuration")}</Form.Label>
                                    </Col>
                                    <Col xs={3} md={3}>
                                        <Form.Label>{t("user.roleActivity")}</Form.Label>
                                    </Col>
                                    {barmanData.userRoles.map((e, i) => (
                                        <Fragment key={i}>
                                            <Col xs={6}>
                                                <BarmanRolesDropdown
                                                    withoutLabel
                                                    selectedRole={e.user_role_code}
                                                    onSelectRole={(v) => {
                                                        const list = [...barmanData.userRoles];
                                                        list[i].user_role_code = v;
                                                        setBarmanData({ ...barmanData, userRoles: list });
                                                        setDisableSubmitting(false);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={3}>
                                                <p className="h5 mt-3">{e.event_code ? e.event_name : t("user.permanentRole")}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Checkbox
                                                    toggle
                                                    checked={e.u2r_active}
                                                    className="mt-3"
                                                    onClick={(event, data) => {
                                                        //console.log(data);
                                                        const list = [...barmanData.userRoles];
                                                        list[i].u2r_active = data.checked;
                                                        setBarmanData({ ...barmanData, userRoles: list });
                                                        setDisableSubmitting(false);
                                                    }}
                                                />
                                            </Col>
                                        </Fragment>
                                    ))}
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <TextareaForm
                                            value={barmanData.user_medical_info}
                                            onChangeValue={(e) => {
                                                setDisableSubmitting(false);
                                                setBarmanData({ ...barmanData, user_medical_info: e.target.value });
                                            }}
                                            labelTxt={t("user.medLabel")}
                                            name="user_medical_info"
                                            placeholderTxt={t("user.medPlaceholder")}
                                            id="userMedInfo"
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <TextareaForm
                                            value={barmanData.user_comment}
                                            onChangeValue={(e) => {
                                                setDisableSubmitting(false);
                                                setBarmanData({ ...barmanData, user_comment: e.target.value });
                                            }}
                                            labelTxt={t("user.userCommentLabel")}
                                            name="user_comment"
                                            placeholderTxt={t("user.userCommentPlaceholder")}
                                            id="userComment"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="button" variant="primary" size="xl" disabled={disableSubmitting} className="my-3" onClick={handleCheckRoleActivity}>
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Fragment>
                        )}
                    </Container>
                </div>
            </Form>
        </Fragment>
    );
};

export default EditBarman;
