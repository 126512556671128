import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";

const EditBarItemPrices = (props) => {
    const { setBasicAlert, activeEvent, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();

    const [fetchEditBarItemPrices, setFetchEditBarItemPrices] = useState(false);
    const [barItemPrices, setBarItemPrices] = useState({
        price: props.item.price.toString(),
        innerPrice: props.item.inner_price.toString(),
        ingamePrice: props.item.ingame_price.toString(),
    }); //konwersja na stringa, bo backend później będzie się pluł "replace is not a function"
    const [disableSubmitting, setDisableSubmitting] = useState(true);

    useEffect(() => {
        const editBarItemPrices = async () => {
            const passData = {
                barItemCode: props.item.bar_item_code,
                barItemPrices: barItemPrices,
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/edit-bar-item-prices`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.editBarItemPrices`), fading: 2000 });
                            props.onCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchEditBarItemPrices) editBarItemPrices();

        return () => {
            setFetchEditBarItemPrices(false);
        };
    }, [fetchEditBarItemPrices]);

    const handleCheckValidation = (e) => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        e.preventDefault();

        const errorMsg = [],
            regExpWhiteSpace = /^\s*$/;

        if (barItemPrices.price === null) {
            errorMsg.push(t("error.emptyPrice"));
            document.getElementById("barItemPrice").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItemPrices.price)) {
            errorMsg.push(t("error.invalidPrice"));
            document.getElementById("barItemPrice").classList.add("is-invalid");
        }
        if (barItemPrices.innerPrice === null) {
            errorMsg.push(t("error.emptyInnerPrice"));
            document.getElementById("barItemInnerPrice").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItemPrices.innerPrice)) {
            errorMsg.push(t("error.invalidInnerPrice"));
            document.getElementById("barItemInnerPrice").classList.add("is-invalid");
        }
        if (barItemPrices.ingamePrice === null) {
            errorMsg.push(t("error.emptyIngamePrice"));
            document.getElementById("barItemIngamePrice").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItemPrices.ingamePrice)) {
            errorMsg.push(t("error.invalidIngamePrice"));
            document.getElementById("barItemIngamePrice").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingNewBarItemCategory") });
            setFetchEditBarItemPrices(true);
        }
    };

    const handleChangeValue = (e) => {
        setBarItemPrices({ ...barItemPrices, [e.target.name]: e.target.value });
        setDisableSubmitting(false);
    };

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false}>
                <Form onSubmit={handleCheckValidation} autoComplete="off">
                    <Modal.Header>
                        <Modal.Title>{t("editBarItemPricesModal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <p className="fs-5 mb-3">
                                    {t("editBarItemPricesModal.text")}
                                    {props.item.bar_item_name}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Label>{t("barItems.price")}</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={t("barItems.pricePlaceholder")}
                                        aria-label="barItemPrice"
                                        aria-describedby="barItemPrice"
                                        size="lg"
                                        name="price"
                                        id="barItemPrice"
                                        onChange={handleChangeValue}
                                        value={barItemPrices.price}
                                    />
                                    <InputGroup.Text id="barItemPrice">{t("currency")}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xs={12}>
                                <Form.Label>{t("barItems.innerPrice")}</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={t("barItems.pricePlaceholder")}
                                        aria-label="barItemInnerPrice"
                                        aria-describedby="barItemInnerPrice"
                                        size="lg"
                                        name="innerPrice"
                                        id="barItemInnerPrice"
                                        onChange={handleChangeValue}
                                        value={barItemPrices.innerPrice}
                                    />
                                    <InputGroup.Text id="barItemInnerPrice">{t("currency")}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xs={12}>
                                <Form.Label>{t("barItems.ingamePrice")}</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={t("barItems.pricePlaceholder")}
                                        aria-label="barItemIngamePrice"
                                        aria-describedby="barItemIngamePrice"
                                        size="lg"
                                        name="ingamePrice"
                                        id="barItemIngamePrice"
                                        onChange={handleChangeValue}
                                        value={barItemPrices.ingamePrice}
                                    />
                                    <InputGroup.Text id="barItemIngamePrice">{t("caps")}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" type="button" size="lg" className="me-3" onClick={props.onClose}>
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="submit" size="lg" disabled={disableSubmitting}>
                            {t("save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default EditBarItemPrices;
