import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import { LoginDataContext } from "../structure/LoggedPages";

const SettleTheBillModal = (props) => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [fetchSettleAndCloseBill, setFetchSettleAndCloseBill] = useState(false);
    const [disableSubmitting, setDisableSubmitting] = useState(false);

    useEffect(() => {
        const settleAndCloseBill = async () => {
            const passData = {
                barClientID: props.barClientID,
                balance: props.balance,
                partID: props.partID,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/settle-and-close-bill`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.billSettled`), fading: 2000 });
                            props.onCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchSettleAndCloseBill) settleAndCloseBill();

        return () => {
            setFetchSettleAndCloseBill(false);
        };
    }, [fetchSettleAndCloseBill]);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onClose}>
                <Modal.Header>
                    <Modal.Title>{t("settleTheBillModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        {t("settleTheBillModal.text")} <b>{props.barClientName}</b>.
                    </p>
                    <p className="mb-2">
                        {t("barClients.orderSum")}:{" "}
                        {props.ordersSum > 0 ? (
                            <>
                                {props.ordersSum} {t("currency")}
                            </>
                        ) : (
                            <span className="text-muted">{t("barClients.noOrders")}</span>
                        )}
                    </p>
                    <p className="mb-2">
                        {t("barClients.paymentSum")}: {props.paymentSum ? props.paymentSum : 0} {t("currency")}
                    </p>
                    <p className="mb-4">
                        {t("barClients.balance")}:{" "}
                        <b className={props.balance < 0 ? "text-danger fs-5" : props.balance > 0 ? "text-primary fs-5" : "fs-5"}>
                            {props.balance} {t("currency")}
                        </b>
                    </p>
                    <p className="mb-2">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                        <span dangerouslySetInnerHTML={{ __html: t("settleTheBillModal.text2") }}></span>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" size="lg" className="me-3" onClick={props.onClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" type="button" size="lg" onClick={() => setFetchSettleAndCloseBill(true)} disabled={disableSubmitting}>
                        {t("settleTheBillModal.btn")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SettleTheBillModal;
