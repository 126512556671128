import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SubmitOrderModal = (props) => {
    const { t } = useTranslation();

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Body>
                <Row>
                    <Col className="text-center my-4">
                        <p className="h3">{t("barCart.orderPlaced")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button size="xl" variant="primary" className="w-100 my-4" onClick={props.onSubmitOrder}>
                            {t("barCart.submitCashSell")}
                        </Button>
                    </Col>
                    <Col xs={6} className="my-4">
                        <Button variant="outline-primary" onClick={props.onClose}>
                            {t("barCart.goBack")}
                        </Button>
                    </Col>
                    <Col xs={6} className="my-4 text-lg-end">
                        <Button variant="outline-secondary" onClick={props.onClearOrder}>
                            {t("barCart.cancelTransaction")}
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SubmitOrderModal;
