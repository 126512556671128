import React, { useContext } from "react";
import { NavDropdown } from "react-bootstrap";
import { LoginDataContext } from "../structure/LoggedPages";

const NavDropdownItem = (props) => {
    const { navName, title, pageName } = props;
    const { setCurrentPage } = useContext(LoginDataContext);
    return (
        <NavDropdown.Item
            id={`${navName}Nav`}
            onClick={() => {
                setCurrentPage({ nav: navName, subpage: pageName, params: "" });
            }}
        >
            {title}
        </NavDropdown.Item>
    );
};

export default NavDropdownItem;
