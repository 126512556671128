import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
const TextareaForm = (props) => {
    const { value, onChangeValue, labelTxt, name, req, placeholderTxt, minHeight, id, size } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {labelTxt} {req && <span className="required">*</span>}
                </Form.Label>
                <Form.Control
                    id={id}
                    as="textarea"
                    rows={3}
                    placeholder={placeholderTxt}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        document.getElementById(id).classList.remove("is-invalid");

                        onChangeValue(e);
                    }}
                    className={req ? "req mb-3" : "mb-3"}
                    style={{ minHeight: minHeight ? minHeight : "auto" }}
                    size={size ? size : "lg"}
                />
            </Form.Group>
        </Fragment>
    );
};

export default TextareaForm;
