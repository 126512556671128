import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import PermissionModal from "../../modals/PermissionModal";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";
import { BarClientContext } from "./BarClients";
import ChooseBarClient from "./ChooseBarClient";

const SettleTheBill = () => {
    const { setBasicAlert, fetchURL, QRData, activeEvent, setQRData, setQRscanner } = useContext(BasicContext);
    const { currentPage, setLoggedUserQR, loggedUserQR } = useContext(LoginDataContext);
    const { getBarClient, barClientData, setGetBarClient, loadingBarClientData, setBarClientData } = useContext(BarClientContext);
    const { t } = useTranslation();

    const [disableSubmitting, setDisableSubmitting] = useState(false);
    const [fetchSettleAndCloseBill, setFetchSettleAndCloseBill] = useState(false);

    const [showPermissionModal, setShowPermissionModal] = useState("MANAG");
    const [permissionAllowed, setPermissionAllowed] = useState(false);

    useEffect(() => {
        setLoggedUserQR(null);
    }, []);

    useEffect(() => {
        const settleAndCloseBill = async () => {
            const passData = {
                balance: barClientData.client_balance,
                partID: barClientData.part_id,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/settle-and-close-bill`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.billSettled`), fading: 2000 });
                            setGetBarClient({ qrCode: null, barClientID: null });
                            setBarClientData(null);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchSettleAndCloseBill) {
            setDisableSubmitting(true);
            settleAndCloseBill();
        }

        return () => {
            setFetchSettleAndCloseBill(false);
        };
    }, [fetchSettleAndCloseBill]);

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setPermissionAllowed(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData && !permissionAllowed) checkPermission();
        else if (QRData && permissionAllowed) setGetBarClient({ qrCode: QRData, barClientID: null });

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />
            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show />}
            {permissionAllowed && (
                <div className="scrolled-content">
                    <Container>
                        <ChooseBarClient barClientID={getBarClient.barClientID} onSelectBarClient={(v) => setGetBarClient({ qrCode: null, barClientID: v })} activeEvent={activeEvent} />

                        {loadingBarClientData ? (
                            <Row>
                                <Col>
                                    <LoadingProgressBar />
                                </Col>
                            </Row>
                        ) : (
                            barClientData && (
                                <Fragment>
                                    {barClientData.bar_client_stat_code === "INACTIVE" ? (
                                        <Row>
                                            <Col xs={12} className="mb-3">
                                                <Alert variant="info">
                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                                    {t("barClients.barClientBillSettled")}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    ) : barClientData.bar_client_stat_code === "BANNED" ? (
                                        <Row>
                                            <Col xs={12} className="mb-3">
                                                <Alert variant="danger">
                                                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                                                    {t("barClients.barClientNotActiveTxt1")}
                                                    {t(`barClients.statuses.${barClientData.bar_client_stat_code}`)}
                                                    {t("barClients.barClientNotActiveTxt2")}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col xs={12}>
                                                <p className="fs-5">
                                                    {t("settleTheBillModal.text")} <b>{barClientData.participant_fullname}</b>.
                                                </p>
                                                <p className="mb-2">
                                                    {t("barClients.orderSum")}:{" "}
                                                    {barClientData.sum_orders_value > 0 ? (
                                                        <>
                                                            {barClientData.sum_orders_value} {t("currency")}
                                                        </>
                                                    ) : (
                                                        <span className="text-muted">{t("barClients.noOrders")}</span>
                                                    )}
                                                </p>
                                                <p className="mb-2">
                                                    {t("barClients.paymentSum")}: {barClientData.sum_bar_payments} {t("currency")}
                                                </p>
                                                <p className="mb-2">
                                                    {t("barClients.amountFree.label")}: {barClientData.amount_free} {t("currency")}
                                                </p>
                                                <p className="mb-4">
                                                    {t("barClients.balance")}:{" "}
                                                    <b className={barClientData.client_balance < 0 ? "text-danger fs-5" : barClientData.client_balance > 0 ? "text-primary fs-5" : "fs-5"}>
                                                        {barClientData.client_balance} {t("currency")}
                                                    </b>
                                                </p>
                                                <p className="mb-2">
                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                                    <span dangerouslySetInnerHTML={{ __html: t("settleTheBillModal.text2") }}></span>
                                                </p>
                                            </Col>
                                            <Col xs={12}>
                                                <Button variant="primary" type="button" size="xl" onClick={() => setFetchSettleAndCloseBill(true)} disabled={disableSubmitting}>
                                                    {t("settleTheBillModal.btn")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Fragment>
                            )
                        )}
                    </Container>
                </div>
            )}
        </Fragment>
    );
};

export default SettleTheBill;
