import React, { Fragment, Suspense } from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./core/Core.css";
import "./bar/Bar.css";
import App from "./bar/App";
import "./i18n";
ReactDOM.render(
    <Suspense fallback={<Fragment></Fragment>}>
        <App />
    </Suspense>,
    document.getElementById("root")
);
