import React, { Fragment } from "react";
import { Dropdown } from "semantic-ui-react";
import $ from "jquery";

const PartToDuty = (props) => {
    const { value, onSelectPart, t, partsList, i } = props;
    return (
        <Fragment>
            <Dropdown
                value={value}
                id={`partToDuty${i}`}
                className="mb-3"
                placeholder={t("selectOrType")}
                fluid
                selection
                options={partsList}
                onChange={(event, { value }) => {
                    onSelectPart(value);
                    $(`#partToDuty${i}`).removeClass("danger");
                }}
            />
        </Fragment>
    );
};

export default PartToDuty;
