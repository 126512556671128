import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import PermissionModal from "../../modals/PermissionModal";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";
import $ from "jquery";

let thead, tbody;

const ScheduleTable = () => {
    const { setBasicAlert, fetchURL, activeEvent, QRData, setQRscanner, setQRData } = useContext(BasicContext);
    const { setLoggedUserQR, setNextPage } = useContext(LoginDataContext);
    const { t } = useTranslation();
    const [goToPage, setGoToPage] = useState(null);

    const [loading, setLoading] = useState(true);
    const [duties, setDuties] = useState(null);
    const [showPermissionModal, setShowPermissionModal] = useState(null);

    useEffect(() => {
        const getSchedule = async () => {
            try {
                const response = await fetch(`${fetchURL}/get-schedule`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else {
                            const drawTable = fetchedData.barDuties;

                            let prevLabel = null;
                            drawTable.forEach((e) => {
                                //console.log(e.date, prevLabel, e.date !== prevLabel);
                                if (e.date !== prevLabel) {
                                    prevLabel = e.date;
                                    //console.log("thead push");
                                    thead.push({
                                        date: e.date,
                                        label: (
                                            <span>
                                                {e.date}
                                                <br />
                                                {e.duty_period_label}
                                            </span>
                                        ),
                                    });
                                }

                                let repeat = false;
                                tbody.forEach((t) => {
                                    if (e.start === t.start) {
                                        repeat = true;
                                    }
                                });
                                if (!repeat) {
                                    tbody.push({ start: e.start, label: `${e.start} - ${e.end}` });
                                }
                            });

                            function sorting(a, b) {
                                if (a.start > b.start) return 1;
                                else return -1;
                            }

                            tbody = tbody.sort(sorting);
                            tbody.unshift({ start: null, label: null });

                            //console.log(thead);
                            //console.log(tbody);

                            setDuties(fetchedData.barDuties);
                        }
                    });
                }
            } catch (err) {
                //console.log(err);
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (activeEvent && activeEvent !== "noActiveEvent") {
            thead = [{ date: null, label: null }];
            tbody = [];
            getSchedule();
        }
    }, []);

    useEffect(() => {
        if (duties) setLoading(false);
    }, [duties]);

    const addClassLarp = (i, j) => {
        $(document).ready(function() {
            $(`#dutyCell${i}${j}`).addClass("larp");
        });
    };

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setNextPage(goToPage);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData && goToPage) checkPermission();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    return (
        <Fragment>
            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show />}
            {activeEvent === "noActiveEvent" ? (
                <Row>
                    <Col className="text-center">
                        <p className="text-muted">{t("duties.noActiveEvent")}</p>
                    </Col>
                </Row>
            ) : (
                <Fragment>
                    {loading ? (
                        <Row>
                            <Col>
                                <LoadingProgressBar />
                            </Col>
                        </Row>
                    ) : duties.length === 0 ? (
                        <Row>
                            <Col className="text-center">
                                <p className="mt-4 mb-2">{t("duties.noSchedule")}</p>
                            </Col>
                        </Row>
                    ) : (
                        <Fragment>
                            <Container className="py-3 bar-nav-details" fluid>
                                <Row>
                                    <Col xs={12} className="text-end">
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                setLoggedUserQR(null);
                                                setShowPermissionModal("ADMIN");
                                                setGoToPage({ nav: "duties", subpage: "editSchedule", params: "" });
                                            }}
                                        >
                                            {t("duties.editSchedule")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="scrolled-content">
                                <Container className="pt-3" fluid>
                                    <Row>
                                        <Col xs={12}>
                                            <Table className="table-schedule mt-3" bordered>
                                                <tbody>
                                                    {tbody.map((b, i) => (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                {thead.map((h, j) => (
                                                                    <Fragment key={j}>
                                                                        {i === 0 ? (
                                                                            <Fragment>{j > 0 ? <td>{h.label}</td> : <td></td>}</Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                {j === 0 ? (
                                                                                    <td>{b.label}</td>
                                                                                ) : (
                                                                                    <td id={`dutyCell${i}${j}`}>
                                                                                        {duties.map((d, k) => (
                                                                                            <Fragment key={k}>
                                                                                                {d.date === h.date && d.start === b.start && (
                                                                                                    <Fragment>
                                                                                                        {d.is_larp === "Y" && addClassLarp(i, j)}
                                                                                                        {d.participants.map((p, l) => (
                                                                                                            <Fragment key={l}>
                                                                                                                <p /* className={d.is_larp === "Y" ? "larp" : ""} */>{p.user_fullname}</p>
                                                                                                            </Fragment>
                                                                                                        ))}
                                                                                                    </Fragment>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        ))}
                                                                                    </td>
                                                                                )}
                                                                            </Fragment>
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                            </tr>
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default ScheduleTable;
