import React, { Fragment, useContext } from "react";
import { LoginDataContext } from "../../structure/LoggedPages";
import AddBarman from "./AddBarman";
import BarmansList from "./BarmansList";
import EditBarman from "./EditBarman";

const Barmans = () => {
    const { currentPage } = useContext(LoginDataContext);
    return (
        <Fragment>
            {currentPage.subpage === "barmansList" && <BarmansList />}
            {currentPage.subpage === "addBarman" && <AddBarman />}
            {currentPage.subpage === "editBarman" && <EditBarman />}
        </Fragment>
    );
};

export default Barmans;
