import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NoActiveEventAlert = (props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Row>
                <Col xs={{ offset: 2, span: 8 }}>
                    <Alert variant="info" className="w-100 my-4">
                        <FontAwesomeIcon icon={faCircleInfo} className="mr-2" />
                        {t(`info.noActiveEvent.${props.alertTxt}`)}
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default NoActiveEventAlert;
