import React, { Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BlockBarClientModal = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Modal show={true} onHide={props.onClose}>
                <Modal.Header>
                    <Modal.Title>{t("confirmActionModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.modalTxt}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" size="lg" className="me-3" onClick={props.onClose}>
                        {t("confirmActionModal.no")}
                    </Button>
                    <Button variant="primary" type="button" size="lg" onClick={props.onProceed}>
                        {t("confirmActionModal.yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default BlockBarClientModal;
