import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import BarClientsDropdown from "../../forms/BarClientsDropdown";

const ChooseBarClient = (props) => {
    const { setBasicAlert, setQRscanner, QRscanner } = useContext(BasicContext);
    const { t } = useTranslation();
    return (
        <Row>
            <Col xs={4} className="text-start mt-5">
                <Button
                    variant="outline-primary"
                    size="lg"
                    className="mb-3 mt-2"
                    onClick={() => {
                        setBasicAlert(null);
                        setQRscanner({ ...QRscanner, show: true });
                    }}
                >
                    {t("ident.scanQRCode")}
                </Button>
            </Col>
            <Col xs={1} className="mt-5 pt-3 text-start">
                {t("or")}
            </Col>
            <Col xs={7} className="text-start mt-3">
                <BarClientsDropdown selectedBarClient={props.barClientID} onSelectBarClient={props.onSelectBarClient} />
            </Col>
        </Row>
    );
};

export default ChooseBarClient;
