import React, { Fragment, useContext, useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { BasicContext } from "../../App";
import { LoginDataContext } from "../structure/LoggedPages";

const ScanDuty = (props) => {
    const { setBasicAlert, fetchURL, activeEvent, QRData, setQRscanner, setQRData, QRscanner, setLogout } = useContext(BasicContext);
    const { setNextPage } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [barmansList, setBarmansList] = useState([]);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [startDuty, setStartDuty] = useState(false);

    $(document).ready(function() {
        const height = $(window).outerHeight() - $("#scanDutyBar").outerHeight() - $("#scanDutyTxt").outerHeight() - $("#scanDutyBtns").outerHeight();
        //console.log($(window).outerHeight(), $("#scanDutyBar").outerHeight(), $("#scanDutyTxt").outerHeight(), $("#scanDutyBtns").outerHeight());
        //console.log(height);
        $("#scanDutyList").css({ height: `${height}px` });
    });

    useEffect(() => {
        const beginDuty = async () => {
            try {
                const response = await fetch(`${fetchURL}/begin-duty`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        barmansList: barmansList,
                        activeEvent: activeEvent,
                        dutyID: props.duty.id,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setNextPage({ nav: "sell", subpage: "sellMain", params: "" });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (startDuty) beginDuty();

        return () => {
            setStartDuty(false);
        };
    }, [startDuty]);

    useEffect(() => {
        const scanBarman = async () => {
            try {
                const response = await fetch(`${fetchURL}/scan-barman-for-duty`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        activeEvent: activeEvent,
                        dutyID: props.duty.id,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            const list = [...barmansList];
                            list.push(fetchedData.barman);
                            setBarmansList(list);
                            setBasicAlert(null);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData) scanBarman();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
    }, [QRData]);

    useEffect(() => {
        if (barmansList.length === 0) setDisableSubmitting(true);
        else setDisableSubmitting(false);
    }, [barmansList]);

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col id="scanDutyBar">
                        <Button variant="outline-primary" className="my-3" onClick={() => setLogout(true)}>
                            {t("user.logout")}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" xs={12} id="scanDutyTxt">
                        <p className="title font-light mb-2">
                            {t("duties.dutyBeginTxt1")} <b>{props.duty.date}</b> {t("duties.dutyBeginTxt2")}{" "}
                            <b>
                                {props.duty.start} - {props.duty.end}
                            </b>
                        </p>
                        <p className="mb-3">{t("duties.dutyBeginTxt3")}</p>
                    </Col>
                    <Col xs={12} id="scanDutyList" className="border py-3">
                        <p className="text-center">{t("duties.onDuty")}</p>
                        <Row>
                            {barmansList.map((barman, i) => (
                                <Fragment key={i}>
                                    <Col xs={6} md={10} className="ps-3">
                                        {barman.userName}
                                    </Col>
                                    <Col xs={6} md={2} className="text-end pe-3 py-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                const list = [...barmansList];
                                                list.splice(i, 1);
                                                setBarmansList(list);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </Col>
                                </Fragment>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Row id="scanDutyBtns">
                    <Col xs={12} md={6} className="py-3">
                        <Button variant="outline-primary" size="lg" onClick={() => setQRscanner({ ...QRscanner, show: true })}>
                            {t("barmans.addBarman")}
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="text-end py-3">
                        <Button variant="outline-primary" size="lg" disabled={disableSubmitting} onClick={() => setStartDuty(true)}>
                            {t("duties.beginSell")}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ScanDuty;
