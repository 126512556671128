import { faExclamationCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { BasicContext } from "../../../App";
import NoActiveEventAlert from "../../alerts/NoActiveEventAlert";
import AddBarClientModal from "../../modals/AddBarClientModal";
import SubmitOrderModal from "../../modals/SubmitOrderModal";
import NavDetails from "../../navigation/NavDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import { LoginDataContext } from "../../structure/LoggedPages";
import ChooseBarClient from "../barClients/ChooseBarClient";

const AddToCredit = () => {
    const { activeEvent, fetchURL, setBasicAlert, setQRscanner, QRData, setQRData } = useContext(BasicContext);
    const { currentPage, cart, setChangedValuesInFormes, goToPreviousPage, setCart } = useContext(LoginDataContext);

    const [selectedBarClient, setSelectedBarClient] = useState({ id: null, typeCode: null });
    const [allowTakeOnCredit, setAllowTakeOnCredit] = useState(false);
    const [barClientAlert, setBarClientAlert] = useState(null);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [checkBarClientData, setCheckBarClientData] = useState(false);
    const [showAddNewBarClient, setShowAddNewBarClient] = useState(false);
    const [showSubmitOrderModal, setShowSubmitOrderModal] = useState(false);
    const [submitOrder, setSubmitOrder] = useState(false);
    const [goBack, setGoBack] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (goBack) {
            goToPreviousPage();
        }
    }, [goBack]);

    useEffect(() => {
        const addBarOrder = async () => {
            const passData = {
                cart: cart,
                barClientID: selectedBarClient.id,
                activeEvent: activeEvent,
            };
            //console.log(passData);
            try {
                const response = await fetch(`${fetchURL}/add-bar-order`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t("success.submitOrder"), fading: 1000 });
                            setShowSubmitOrderModal(false);
                            setCart([]);
                            setChangedValuesInFormes(false);
                            setLoading(true);
                            setTimeout(() => {
                                setGoBack(true);
                            }, 200);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (submitOrder) addBarOrder();
        return () => {
            setSubmitOrder(false);
        };
    }, [submitOrder]);

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        const errorMsg = [];

        if (!selectedBarClient.id) {
            errorMsg.push(t("error.noSelectedBarClient"));
        } else if (!allowTakeOnCredit) {
            errorMsg.push(t("error.notAllowedToTakeOnCredit"));
        }

        if (cart.length === 0) {
            errorMsg.push(t("error.emptyCart"));
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setBasicAlert(null);
            setShowSubmitOrderModal(true);
        }
    };

    const cartSum = () => {
        let sum = 0,
            sumIngame = 0,
            sumInner = 0;
        cart.forEach((e) => {
            sum += e.price_amount;
            sumIngame += e.ingame_price_amount;
            sumInner += e.inner_price * e.amount;
        });

        sum = sum.toString().replace(".", ",");
        sumIngame = sumIngame.toString().replace(".", ",");
        sumInner = sumInner.toString().replace(".", ",");

        return (
            <>
                {selectedBarClient.typeCode === "INNER" ? (
                    <>
                        <span className="text-decoration-line-through text-muted">
                            {sum} {t("currency")}
                        </span>{" "}
                        {sumInner} {t("currency")}
                    </>
                ) : (
                    <>
                        {sum} {t("currency")}
                    </>
                )}{" "}
                {sumIngame > 0 ? `${sumIngame} ${t("caps")}` : ""}
            </>
        );
    };

    useEffect(() => {
        const getAndCheckBarClient = async () => {
            //pobierz dane o kliencie na podstawie qr kodu i sprawdź czy może wziąć krechę
            setBarClientAlert(null);
            setBasicAlert(null);

            const passData = {
                activeEvent: activeEvent,
                identValue: QRData,
            };
            try {
                const response = await fetch(`${fetchURL}/get-and-check-bar-client`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setSelectedBarClient({ ...selectedBarClient, id: null });
                        } else {
                            setSelectedBarClient({ ...selectedBarClient, id: fetchedData.barClientID });
                            setCheckBarClientData(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData) {
            setQRscanner(false);
            getAndCheckBarClient();
        }

        return () => {
            setQRData(null);
        };
    }, [QRData]);

    useEffect(() => {
        if (!disableSubmitting) setChangedValuesInFormes(true);
    }, [disableSubmitting]);

    useEffect(() => {
        //sprawdzanie czy klient może wziąc produkty na krechę (czy status ma aktywny, czy nie przekroczył limitu)
        const checkBarClientCorectness = async () => {
            setBarClientAlert(null);
            //console.log(selectedBarClient);
            const passData = {
                activeEvent: activeEvent,
                barClientID: selectedBarClient.id,
            };
            try {
                const response = await fetch(`${fetchURL}/check-bar-client`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else if (fetchedData.status === "barClientNotActive") {
                            setBarClientAlert({
                                variant: "danger",
                                message: `${t("sell.barClientNotActiveTxt1")}${t(`barClients.statuses.${fetchedData.statCode}`)}${t("sell.barClientNotActiveTxt2")}`,
                            });
                            setSelectedBarClient({ ...selectedBarClient, typeCode: fetchedData.typeCode });
                            setDisableSubmitting(true);
                        } else if (fetchedData.status === "partHasNoBarClient") {
                            setBarClientAlert({
                                variant: "danger",
                                message: t("danger.partHasNoBarClient"),
                            });
                            setSelectedBarClient({ ...selectedBarClient, typeCode: fetchedData.typeCode });
                            setDisableSubmitting(true);
                        } else {
                            if (fetchedData.status === "limitExceeded") setBarClientAlert({ variant: "warning", message: t("warning.limitExceeded") });
                            setAllowTakeOnCredit(true);
                            setSelectedBarClient({ ...selectedBarClient, typeCode: fetchedData.typeCode });
                            setDisableSubmitting(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (checkBarClientData) checkBarClientCorectness();

        return () => {
            setCheckBarClientData(false);
        };
    }, [checkBarClientData]);

    return (
        <Fragment>
            {loading ? (
                <Row>
                    <Col>
                        <LoadingSpinnerInner />
                    </Col>
                </Row>
            ) : (
                <Fragment>
                    <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} backBtn />

                    {activeEvent === "noActiveEvent" ? (
                        <NoActiveEventAlert alertTxt={currentPage.subpage} />
                    ) : (
                        <Form onSubmit={handleCheckValidation} autoComplete="off">
                            <div className="scrolled-content">
                                <Container>
                                    <Row>
                                        <Col xs={12} lg={8} md={9} className="my-3">
                                            <ChooseBarClient
                                                barClientID={selectedBarClient.id}
                                                onSelectBarClient={(v) => {
                                                    setSelectedBarClient({ id: v, typeCode: null });
                                                    setCheckBarClientData(true);
                                                    setDisableSubmitting(false);
                                                }}
                                            />
                                            <Row>
                                                {barClientAlert && (
                                                    <Col xs={12} className="mb-3">
                                                        <Alert variant={barClientAlert.variant}>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    barClientAlert.variant === "danger"
                                                                        ? faExclamationCircle
                                                                        : barClientAlert.variant === "warning"
                                                                        ? faExclamationTriangle
                                                                        : faInfoCircle
                                                                }
                                                                className="me-2"
                                                            />
                                                            {barClientAlert.message}
                                                        </Alert>
                                                    </Col>
                                                )}

                                                <Col xs={12} className={!barClientAlert ? "mt-5 mb-3" : "mb-3"}>
                                                    <Button variant="primary" size="lg" disabled={!allowTakeOnCredit || disableSubmitting} type="submit">
                                                        {t("choose")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} lg={4} md={3} className="d-none d-md-block my-md-3 my-0">
                                            <p className="h5 mb-4 px-2">{t("barCart.summary")}</p>
                                            <Table id="cartSummary">
                                                <tbody>
                                                    {cart.map((e, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {e.amount}x <b>{e.bar_item_name}</b>
                                                            </td>
                                                            <td className="text-end text-nowrap">
                                                                {selectedBarClient.typeCode && selectedBarClient.typeCode === "INNER" ? (
                                                                    <Fragment>
                                                                        <span className="text-decoration-line-through text-muted">
                                                                            {e.price_amount.toString().replace(".", ",")} {t("currency")}
                                                                        </span>{" "}
                                                                        {(e.inner_price * e.amount).toString().replace(".", ",")} {t("currency")}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {e.price_amount.toString().replace(".", ",")} {t("currency")}
                                                                    </Fragment>
                                                                )}{" "}
                                                                {e.ingame_price > 0 && `${e.ingame_price_amount.toString().replace(".", ",")} ${t("caps")}`}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr className="hr">
                                                        <td colSpan={2}>
                                                            <hr />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("barCart.sum")}:</td>
                                                        <td className="text-end text-nowrap">{cartSum()}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Form>
                    )}
                </Fragment>
            )}

            {showAddNewBarClient && <AddBarClientModal show onClose={() => setShowAddNewBarClient(false)} />}
            {showSubmitOrderModal && (
                <SubmitOrderModal
                    show
                    onClose={() => setShowSubmitOrderModal(false)}
                    onSubmitOrder={() => {
                        setSubmitOrder(true);
                        setBasicAlert({ variant: "info", message: t("info.submitOrder") });
                    }}
                    onClearOrder={() => {
                        setShowSubmitOrderModal(false);
                        setCart([]);
                        setChangedValuesInFormes(false);
                        setLoading(true);
                        setTimeout(() => {
                            setGoBack(true);
                        }, 200);
                    }}
                />
            )}
        </Fragment>
    );
};

export default AddToCredit;
