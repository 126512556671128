import React, { Fragment } from "react";
import { Row } from "react-bootstrap";
import BarItemsCart from "./BarItemsCart";
import BarItemsList from "./BarItemsList";

const SellMain = () => {
    return (
        <Fragment>
            <Row className="h-100">
                <BarItemsList />
                <BarItemsCart />
            </Row>
        </Fragment>
    );
};

export default SellMain;
