import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { BasicContext } from "../../../App";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";
import { LoginDataContext } from "../../structure/LoggedPages";

const BarItemsList = () => {
    const { fetchURL, setBasicAlert, activeEvent } = useContext(BasicContext);
    const { setCart, cart } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [productsList, setProductsList] = useState(null);
    const [loading, setLoading] = useState(false);

    const [selectedCat, setSelectedCat] = useState(null);

    const bufferIcon = (icon) => {
        if (!icon.blob_data) return null;
        else {
            const blob = icon.blob_data;
            const buffer = Buffer.from(blob);
            const bufferBase64 = buffer.toString("base64");

            const bufferedIcon = {
                data: bufferBase64,
                type: icon.blob_file_type,
                name: icon.blob_name,
            };

            return bufferedIcon;
        }
    };

    const smallFont = () => {
        $(".bar-item").each(function() {
            if ($(this).text().length > 10) $(this).addClass("small");
            else $(this).removeClass("small");
        });
        $(".bar-cart").css({ height: $(".bar-cart").outerWidth() });
    };

    useEffect(() => {
        const getBarItemsList = async () => {
            const passData = {
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/bar-items-list`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });
                //console.log(response);
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            const productsList = fetchedData.list;

                            productsList.forEach((e) => {
                                e.icon = bufferIcon(e);
                                delete e.blob_data;
                                delete e.blob_file_type;
                                delete e.blob_name;
                                if (e.type === "category") {
                                    let itemsList = e.itemsList;
                                    itemsList.forEach((el) => {
                                        el.icon = bufferIcon(el);
                                        delete el.blob_data;
                                        delete el.blob_file_type;
                                        delete el.blob_name;
                                    });
                                }
                            });

                            //console.log(productsList);
                            setProductsList(productsList);
                            smallFont();
                        }
                    });
                }
            } catch (err) {
                //console.log(err);
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        getBarItemsList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addToCart = (item) => {
        let tmpCart = [...cart],
            itemInCart = false;

        tmpCart.forEach((e) => {
            if (e.bar_item_code === item.bar_item_code) {
                itemInCart = true;
                e.amount += 1;
                e.price_amount = e.amount * e.price;
                e.ingame_price_amount = e.amount * e.ingame_price;
            }
        });

        if (!itemInCart) {
            item.amount = 1;
            item.price_amount = item.amount * item.price;
            item.ingame_price_amount = item.amount * item.ingame_price;
            tmpCart.push(item);
        }

        setCart(tmpCart);
    };

    const handleSelectCat = (e) => {
        setLoading(true);
        setTimeout(() => {
            //timeout ustawiony, by na następnym buttonie, który zostanie wyświetlony, nie było efektu hover/focus
            setSelectedCat(e);
            setLoading(false);
            smallFont();
        }, 50);
    };

    return (
        <Fragment>
            <Col xs={12} md={9} id="barItemsList">
                {!productsList ? (
                    <div className="text-center">
                        <LoadingProgressBar />
                    </div>
                ) : loading ? (
                    <Row>
                        <Col className="text-start pt-5 ps-3">
                            <LoadingSpinnerSm />
                        </Col>
                    </Row>
                ) : productsList.length === 0 ? (
                    <p className="text-muted my-3">{t("barItems.emptyList")}</p>
                ) : !selectedCat ? (
                    <Fragment>
                        <Button variant="link" className="my-3 d-block">
                            {t("barItems.allItems")}
                        </Button>
                        {productsList.map((e, i) => (
                            <Fragment key={i}>
                                <Button
                                    variant="outline-primary"
                                    className="bar-item"
                                    onClick={e.type === "category" ? () => handleSelectCat(e) : () => addToCart(e)}
                                    disabled={e.type === "item" && e.bar_item_active === "N" ? true : false}
                                >
                                    <Image fluid src={e.icon ? `data:${e.icon.type};base64,${e.icon.data}` : "images/alkochemicy_logo.svg"} alt={e.icon ? e.icon.name : "alkochemicy_logo"} />
                                    {e.bar_item_name}
                                </Button>
                            </Fragment>
                        ))}
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button variant="outline-secondary" className="my-3 d-block" onClick={() => handleSelectCat(null)}>
                            {t("goBack")}
                        </Button>
                        {selectedCat.itemsList.map((e, i) => (
                            <Fragment key={i}>
                                <Button variant="outline-primary" className="bar-item" onClick={() => addToCart(e)} disabled={e.bar_item_active === "N" ? true : false}>
                                    <Image fluid src={e.icon ? `data:${e.icon.type};base64,${e.icon.data}` : "images/alkochemicy_logo.svg"} alt={e.icon ? e.icon.name : "alkochemicy_logo"} />
                                    {e.bar_item_name}
                                </Button>
                            </Fragment>
                        ))}
                    </Fragment>
                )}
            </Col>
        </Fragment>
    );
};

export default BarItemsList;
