import React, { Fragment } from "react";
import { Row, Col, Image } from "react-bootstrap";

const LogoAndTitle = (props) => {
    const { title } = props;
    return (
        <Fragment>
            <Row className="pt-3 pt-xxl-5">
                <Col lg={{ span: 6, offset: 3 }} className="text-center" md={{ span: 6, offset: 3 }}>
                    <Image id="mainLogo" fluid src="images/alkochemicy_logo.svg" className="mb-xxl-5 mb-3 mt-xxl-4 mt-2 w-50" />
                    <h1 className="h3 mb-4">{title}</h1>
                </Col>
            </Row>
        </Fragment>
    );
};

export default LogoAndTitle;
