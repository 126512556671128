import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AmountLimit = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Form.Label>
                {t("barClients.amountLimit.label")} {props.req && <span className="required"> *</span>}{" "}
                {props.helper && (
                    <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                            <Popover id="popoverAmountLimit">
                                <Popover.Body>{t("barClients.amountLimit.helper")}</Popover.Body>
                            </Popover>
                        }
                    >
                        <Button variant="link">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </Button>
                    </OverlayTrigger>
                )}
            </Form.Label>

            <InputGroup className="mb-3">
                <Form.Control
                    type="number"
                    placeholder={t("barClients.amountLimit.placeholder")}
                    onChange={(e) => props.onChangeValue(e.target.value)}
                    size="lg"
                    aria-describedby="amountLimitAddon"
                    value={props.value}
                    disabled={props.disabled}
                ></Form.Control>
                <InputGroup.Text id="amountLimitAddon" className="text-uppercase">
                    {t("currency")}
                </InputGroup.Text>
            </InputGroup>
        </Fragment>
    );
};

export default AmountLimit;
