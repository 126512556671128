import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Phone = (props) => {
    const { value, onChangeValue, name, id, req, size } = props;
    const { t } = useTranslation();
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {t("user.phoneLabel")}
                    {req && <span className="required"> *</span>}
                </Form.Label>
                <Form.Control
                    id={id}
                    type="text"
                    placeholder={t("user.phonePlaceholder")}
                    name={name}
                    value={value}
                    onChange={(e) => onChangeValue(e)}
                    className={req ? "req" : ""}
                    size={size ? size : "lg"}
                />
            </Form.Group>
        </Fragment>
    );
};

export default Phone;
