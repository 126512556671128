import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AmountFree = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Form.Label>
                {t("barClients.amountFree.label")} {props.req && <span className="required"> *</span>}{" "}
                {props.helper && (
                    <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                            <Popover id="popoverAmoutFree">
                                <Popover.Body>{t("barClients.amountFree.helper")}</Popover.Body>
                            </Popover>
                        }
                    >
                        <Button variant="link">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </Button>
                    </OverlayTrigger>
                )}
            </Form.Label>

            <InputGroup className="mb-3">
                <Form.Control
                    type="number"
                    placeholder={t("barClients.amountFree.placeholder")}
                    onChange={(e) => {
                        document.getElementById("amountFree").classList.remove("is-invalid");
                        props.onChangeValue(e.target.value);
                    }}
                    size="lg"
                    aria-describedby="amountFreeAddon"
                    value={props.value}
                    disabled={props.disabled}
                    id="amountFree"
                ></Form.Control>
                <InputGroup.Text id="amountFreeAddon" className="text-uppercase">
                    {t("currency")}
                </InputGroup.Text>
            </InputGroup>
        </Fragment>
    );
};

export default AmountFree;
