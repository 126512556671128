import React, { Fragment, useContext } from "react";
import { LoginDataContext } from "../../structure/LoggedPages";
import AddToCredit from "./AddToCredit";
import SellMain from "./SellMain";

const Sell = () => {
    const { currentPage } = useContext(LoginDataContext);
    return (
        <Fragment>
            {currentPage.subpage === "sellMain" && <SellMain />}
            {currentPage.subpage === "addToCredit" && <AddToCredit />}
        </Fragment>
    );
};

export default Sell;
