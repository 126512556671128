import React, { Fragment } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StartingPayment = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Form.Label>
                {t("barClients.startingPayment.label")} {props.req && <span className="required"> *</span>}
            </Form.Label>

            <InputGroup className="mb-3">
                <Form.Control
                    type="number"
                    placeholder={t("barClients.startingPayment.placeholder")}
                    onChange={(e) => {
                        props.onChangeValue(e.target.value);
                        document.getElementById("startingPaymentInput").classList.remove("is-invalid");
                    }}
                    size="lg"
                    aria-describedby="startingPaymentAddon"
                    id="startingPaymentInput"
                    value={props.value}
                ></Form.Control>
                <InputGroup.Text id="startingPaymentAddon" className="text-uppercase">
                    {t("currency")}
                </InputGroup.Text>
            </InputGroup>
        </Fragment>
    );
};

export default StartingPayment;
