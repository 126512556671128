import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Login from "../pages/account/Login";
import LogoAndTitle from "../other/LogoAndTitle";
import { useTranslation } from "react-i18next";

import RememberPass from "../pages/account/RememberPass";
import LoginByQRCode from "../pages/account/LoginByQRCode";

const NotLoggedPages = () => {
    const [subPage, setSubPage] = useState(null);
    const { t } = useTranslation();
    return (
        <div id="notLoggedPages">
            <Container>
                <LogoAndTitle title={t("document.subtitle")} />
                {subPage === "loginForm" ? <Login onSetSubPage={setSubPage} /> : subPage === "rememberPass" ? <RememberPass onSetSubPage={setSubPage} /> : <LoginByQRCode onSetSubPage={setSubPage} />}
            </Container>
        </div>
    );
};

export default NotLoggedPages;
