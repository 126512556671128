import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Email = (props) => {
    const { value, onChangeValue, id, name } = props;
    const { t } = useTranslation();
    return (
        <Fragment>
            <Form.Group className="mb-3">
                <Form.Label>
                    E-mail<span className="required"> *</span>
                </Form.Label>
                <Form.Control
                    id={id}
                    type="email"
                    placeholder={t("form.typeEmail")}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        onChangeValue(e);
                        document.getElementById(id).classList.remove("is-invalid");
                    }}
                    className="req"
                    autoComplete="on"
                    size="lg"
                />
            </Form.Group>
        </Fragment>
    );
};

export default Email;
