import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { faCheck, faInfoCircle, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import { BasicContext } from "./../../App";
import { useTranslation } from "react-i18next";
import PartToDuty from "../forms/PartToDuty";

const SelectPartsToDutyModal = (props) => {
    const { setBasicAlert, fetchURL, activeEvent } = useContext(BasicContext);
    const { t } = useTranslation();

    const [changedValues, setChangedValues] = useState(false);
    const [partsListSelected, setPartsListSelected] = useState([]);
    const [partsListDropdown, setPartsListDropdown] = useState([]);
    const [partsListDownloaded, setPartsListDownloaded] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const [fetchPartsToPeriod, setFetchPartsToPeriod] = useState(false);

    const { onClose, period, onSelectParts } = props;

    useEffect(() => {
        const getPartsToPeriod = async () => {
            const passData = {
                periodID: period.duty_period_id,
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/get-parts-to-period`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else {
                            if (fetchedData.status !== true) setAlert({ variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) });
                            const list = [],
                                partsListDownloaded = [],
                                partsListDropdown = fetchedData.partsList;
                            partsListDropdown.forEach((e, i) => {
                                partsListDownloaded.push(e);
                                list.push({
                                    key: i + 1,
                                    value: e.part_id,
                                    text: (
                                        <p>
                                            {e.user_fullname}{" "}
                                            {e.part_a2p_answer === "Y" ? (
                                                <span>
                                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                </span>
                                            ) : e.part_a2p_answer === "M" ? (
                                                <span>
                                                    <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                </span>
                                            ) : (
                                                <Fragment></Fragment>
                                            )}
                                        </p>
                                    ),
                                });
                            });

                            //console.log("partsListDownloaded: ", partsListDownloaded);
                            //console.log("partsListSelected: ", partsListSelected);

                            //dodawanie wybranych uczestników w trybie edycji jeśli nie są na liście w dropdownie
                            //console.log(partsListSelected);
                            partsListSelected.forEach((e) => {
                                if (e) {
                                    let partIsOnTheList = false;
                                    list.forEach((el) => {
                                        if (el.value === e.partID) partIsOnTheList = true;
                                    });
                                    if (!partIsOnTheList)
                                        list.push({
                                            key: list.length + 1,
                                            value: e.partID,
                                            text: (
                                                <p>
                                                    {e.partName}{" "}
                                                    {e.partAnswer === "Y" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                        </span>
                                                    ) : e.partAnswer === "M" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                        </span>
                                                    ) : (
                                                        <Fragment></Fragment>
                                                    )}
                                                </p>
                                            ),
                                        });
                                }
                            });

                            list.push({ key: list.length + 1, value: "default", text: t("lack") });
                            //console.log("partsListDropdown: ", list);

                            setPartsListDropdown(list);
                            setPartsListDownloaded(partsListDownloaded);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            }
        };

        if (fetchPartsToPeriod) getPartsToPeriod();

        return () => {
            setFetchPartsToPeriod(false);
        };
    }, [fetchPartsToPeriod]);

    useEffect(() => {
        const selectedParts = period.participants,
            partList = [];

        for (let i = 0; i < 4; i++) {
            if (selectedParts[i]) partList.push(selectedParts[i]);
            else partList.push(null);
        }

        //console.log(partList);
        setPartsListSelected(partList);
        setFetchPartsToPeriod(true);
    }, []);

    const handleSelectPart = (partID, i) => {
        const list = [...partsListSelected],
            original = [...partsListDownloaded];

        partsListDropdown.forEach((e) => {
            if (e.value === partID) {
                if (e.value !== "default") {
                    let part = original.filter((p) => p.part_id === partID);
                    list[i] = { partID: partID, partName: part[0].user_fullname, partAnswer: part[0].part_a2p_answer };
                } else list[i] = null;
            }
        });

        setPartsListSelected(list);
        setChangedValues(true);
    };

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setBasicAlert(null);
        let errorMsg = [],
            currentPart = [];

        partsListSelected.forEach((el, i) => {
            if (el) {
                if (currentPart.includes(el.partID)) {
                    errorMsg.push(t("error.partRepeats"));
                    document.getElementById(`partToDuty${i}`).classList.add("is-invalid");
                } else currentPart.push(el.partID);
            }
        });

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            onSelectParts(partsListSelected);
        }
    };

    return (
        <Fragment>
            <Modal show={true} onHide={onClose} size="lg">
                <Form onSubmit={handleCheckValidation} autoComplete="off">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("duties.partsToDutyModal.title")}:<br />
                            {period.duty_period_label} {period.duty_period_date}{" "}
                            <span>
                                {period.duty_period_start} - {period.duty_period_end}
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <Row>
                                {alert && (
                                    <Col xs={12} className="text-center mb-3">
                                        <Alert variant="info">
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                            {alert.message}
                                        </Alert>
                                    </Col>
                                )}
                                <Col xs={12}>
                                    <p className="mb-3">
                                        {t(`duties.partsToDutyModal.text`)}
                                        {!alert && (
                                            <Fragment>
                                                <br />
                                                <FontAwesomeIcon icon={faCheck} /> - {t("duties.partsToDutyModal.text1")}
                                                <br />
                                                <FontAwesomeIcon icon={faQuestion} /> - {t("duties.partsToDutyModal.text2")}
                                            </Fragment>
                                        )}
                                    </p>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        {partsListSelected.map((e, i) => (
                                            <Col xs={12} lg={{ span: 8, offset: 2 }} key={i}>
                                                <PartToDuty value={e ? e.partID : "default"} onSelectPart={(partID) => handleSelectPart(partID, i)} t={t} partsList={partsListDropdown} i={i} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={onClose}>
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="submit" disabled={!changedValues} onClick={handleCheckValidation}>
                            {t("save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default SelectPartsToDutyModal;
