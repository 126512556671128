import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Row, Container, Alert, Table, Dropdown, Button } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
import { BasicContext } from "./../../../App";
import { LoginDataContext } from "../../structure/LoggedPages";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faXmark, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import AddPaymentModal from "../../modals/AddPaymentModal";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import ConfirmActionModal from "../../modals/ConfirmActionModal";
import SettleTheBillModal from "../../modals/SettleTheBillModal";
import { BarClientContext } from "./BarClients";
import BlockBarClientModal from "../../modals/BlockBarClientModal";
import PermissionModal from "../../modals/PermissionModal";
import ChooseBarClient from "./ChooseBarClient";

const BarClientDetails = () => {
    const { setBasicAlert, fetchURL, QRData, setQRData, setQRscanner, activeEvent } = useContext(BasicContext);
    const { currentPage, setLoggedUserQR, loggedUserQR } = useContext(LoginDataContext);
    const { loadingBarClientData, setLoadingBarClientData, getBarClient, barClientData, setFetchBarClient, setGetBarClient } = useContext(BarClientContext);
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState({ show: null });
    const [activateBarClient, setActivateBarClient] = useState(false);
    const [blockBarClient, setBlockBarClient] = useState(false);
    const [showPermissionModal, setShowPermissionModal] = useState(null);
    const [permissionAllowed, setPermissionAllowed] = useState(false);
    const [fetchCancelOrder, setFetchCancelOrder] = useState(false);

    useEffect(() => {
        const block = async () => {
            try {
                const response = await fetch(`${fetchURL}/block-bar-client`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        barClientID: getBarClient.barClientID,
                        partID: barClientData.part_id,
                        loggedUserQR: loggedUserQR,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.barClientBlocked`), fading: 2000 });
                            handleCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (blockBarClient) block();

        return () => {
            setBlockBarClient(false);
        };
    }, [blockBarClient]);

    useEffect(() => {
        const activate = async () => {
            try {
                const response = await fetch(`${fetchURL}/activate-bar-client`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        barClientID: getBarClient.barClientID,
                        partID: barClientData.part_id,
                        loggedUserQR: loggedUserQR,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.barClientActivated`), fading: 2000 });
                            handleCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (activateBarClient) activate();

        return () => {
            setActivateBarClient(false);
        };
    }, [activateBarClient]);

    useEffect(() => {
        const cancelOrder = async () => {
            try {
                const response = await fetch(`${fetchURL}/cancel-order`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        barOrderID: showModal.orderID,
                        loggedUserQR: loggedUserQR,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.orderCanceled`), fading: 1000 });
                            handleCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (fetchCancelOrder) cancelOrder();

        return () => {
            setFetchCancelOrder(false);
        };
    }, [fetchCancelOrder]);

    useEffect(() => {
        if (barClientData && barClientData.orders.length > 0) {
            const orders = barClientData.orders,
                ordersCount = [];
            orders.forEach((o, i) => {
                const date = o.create_date;
                if (ordersCount.filter((e) => e[0] === date).length === 0) ordersCount.push([date, 1, i]);
                else
                    ordersCount.forEach((el) => {
                        if (el[0] === date) el[1] += 1;
                    });
            });
        }
    }, [barClientData]);

    const handleCloseAndRefresh = () => {
        setShowModal({ show: null });
        setLoadingBarClientData(true);
        setFetchBarClient(true);
    };

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setPermissionAllowed(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData && showModal.show) checkPermission();
        else if (QRData && !showModal.show) setGetBarClient({ qrCode: QRData, barClientID: null });

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    useEffect(() => {
        if (showModal.show) {
            setLoggedUserQR(null);
            setShowPermissionModal(showModal.show === "blockTheClient" ? "ADMIN" : "MANAG");
            setPermissionAllowed(false);
        }
    }, [showModal]);

    return (
        <Fragment>
            <NavDetails
                title={`${currentPage.nav}.${currentPage.subpage}`}
                actionDropdown={
                    barClientData &&
                    barClientData.client_status !== "BANNED" && (
                        <Dropdown size="lg">
                            <Dropdown.Toggle variant="outline-primary" id="barClientDetailsActions">
                                {t("actions")}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {barClientData.client_status === "INACTIVE" ? (
                                    <Dropdown.Item as="button" onClick={() => setShowModal({ ...showModal, show: "activateAgain" })}>
                                        {t("barClients.activateAgain")}
                                    </Dropdown.Item>
                                ) : (
                                    <Fragment>
                                        <Dropdown.Item as="button" onClick={() => setShowModal({ ...showModal, show: "addPayment" })}>
                                            {t("barClients.addPayment")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => setShowModal({ ...showModal, show: "settleTheBill" })}>
                                            {t("barClients.settleTheBill")}
                                        </Dropdown.Item>
                                    </Fragment>
                                )}
                                <Dropdown.Item as="button" onClick={() => setShowModal({ ...showModal, show: "blockTheClient" })}>
                                    {t("barClients.blockTheClient")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            />

            <div className="scrolled-content">
                <Container>
                    <ChooseBarClient barClientID={getBarClient.barClientID} onSelectBarClient={(v) => setGetBarClient({ qrCode: null, barClientID: v })} />

                    {loadingBarClientData ? (
                        <Row>
                            <Col>
                                <LoadingProgressBar />
                            </Col>
                        </Row>
                    ) : (
                        barClientData && (
                            <Fragment>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h4 mt-4 mb-3">{barClientData.participant_fullname}</p>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <p className="mb-1">
                                            {t("barClients.typeName")}: {t(`barClients.types.${barClientData.client_type}`)}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.statusName")}:{" "}
                                            <span className={barClientData.client_status === "BANNED" ? "text-danger" : barClientData.client_status === "INACTIVE" ? "text-primary" : ""}>
                                                {t(`barClients.statuses.${barClientData.client_status}`)}
                                            </span>
                                        </p>
                                        <p className="mb-1">
                                            {t("participant.identNumber")}: {barClientData.part_ident_number}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.eventName")}: {barClientData.event_name}
                                        </p>
                                        <p className="mb-1">
                                            {t("user.phone")}: {barClientData.user_phone ? barClientData.user_phone : <span className="text-muted">{t("lack")}</span>}
                                        </p>
                                        <p className="mb-1">
                                            {t("user.email")}: {barClientData.user_email}
                                        </p>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <p className="mb-1">
                                            {t("barClients.amountLimit.label")}: {barClientData.amount_limit === null ? t("barClients.noLimit") : `${barClientData.amount_limit} ${t("currency")}`}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.amountFree.label")}: {barClientData.amount_free} {t("currency")}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.paymentSum")}: {barClientData.sum_bar_payments ? barClientData.sum_bar_payments : 0} {t("currency")}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.orderSum")}:{" "}
                                            {barClientData.sum_orders_value > 0 ? `${barClientData.sum_orders_value} ${t("currency")}` : <span className="text-muted">{t("barClients.noOrders")}</span>}
                                        </p>
                                        <p className="mb-1">
                                            {t("barClients.balance")}:{" "}
                                            <span className={barClientData.client_balance + barClientData.amount_limit < 0 ? "text-danger" : barClientData.client_balance > 0 ? "text-info" : ""}>
                                                {barClientData.client_balance} {t("currency")}
                                            </span>
                                        </p>
                                        {barClientData.amount_limit !== null && barClientData.client_balance + barClientData.amount_limit < 0 && barClientData.client_type === "ACTIVE" && (
                                            <Alert variant="warning" className="alert-small">
                                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                                {t("warning.limitExceeded")}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h5 mt-4 mb-2">{t("barClients.orders.title")}</p>
                                        {barClientData.orders.length === 0 ? (
                                            <p className="text-muted">{t("barClients.noOrders")}</p>
                                        ) : (
                                            <Table bordered variant="dark" hover>
                                                <thead>
                                                    <tr>
                                                        <th>{t("n.o.")}</th>
                                                        <th>{t("barClients.orders.price")}</th>
                                                        <th>{t("barClients.orders.products")}</th>
                                                        <th>{t("barClients.orders.status")}</th>
                                                        <th>{t("barClients.orders.date")}</th>
                                                        <th>{t("actions")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {barClientData.orders.map((e, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td style={{ whiteSpace: "nowrap" }}>
                                                                {barClientData.client_type === "INNER" ? e.amount * e.inner_price : e.amount * e.price} {t("currency")}{" "}
                                                                {e.ingame_price > 0 && (
                                                                    <>
                                                                        {e.amount * e.ingame_price} {t("caps")}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {e.amount}x {e.bar_item_name}
                                                            </td>
                                                            <td>
                                                                {e.bar_order_stat_code === "EXECUTED" ? (
                                                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                                ) : e.bar_order_stat_code === "CANCELED" ? (
                                                                    <FontAwesomeIcon icon={faXmark} className="text-danger" />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>
                                                            <td>{e.create_date}</td>
                                                            <td className="text-center">
                                                                {e.bar_order_stat_code === "EXECUTED" && (
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        size="sm"
                                                                        onClick={() =>
                                                                            setShowModal({
                                                                                show: "cancelOrder",
                                                                                orderID: e.bar_order_id,
                                                                                orderName: (
                                                                                    <span>
                                                                                        {e.amount}x {e.bar_item_name} -{" "}
                                                                                        {barClientData.client_type === "INNER" ? e.amount * e.inner_price : e.amount * e.price} {t("currency")}{" "}
                                                                                        {e.ingame_price > 0 && (
                                                                                            <>
                                                                                                {e.amount * e.ingame_price} {t("caps")}
                                                                                            </>
                                                                                        )}
                                                                                    </span>
                                                                                ),
                                                                            })
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faBan} />
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </Col>
                                    <Col xs={12}>
                                        <p className="h5 mt-4 mb-2">{t("barClients.payments.title")}</p>
                                        {barClientData.payments.length === 0 ? (
                                            <p className="text-muted mb-3">{t("barClients.noPayments")}</p>
                                        ) : (
                                            <Table bordered variant="dark" hover>
                                                <thead>
                                                    <tr>
                                                        <th>{t("n.o.")}</th>
                                                        <th>{t("barClients.payments.amount")}</th>
                                                        <th>{t("barClients.payments.type")}</th>
                                                        <th>{t("barClients.payments.comment")}</th>
                                                        <th>{t("barClients.payments.date")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {barClientData.payments.map((e, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td style={{ whiteSpace: "nowrap" }}>
                                                                {e.payment_amount > 0 && "+"}
                                                                {e.payment_amount} {t("currency")}
                                                            </td>
                                                            <td>{e.payment_type_name}</td>
                                                            <td>{e.payment_comment ? e.payment_comment : <span className="text-muted">{t("lack")}</span>}</td>
                                                            <td style={{ width: "150px" }}>{e.create_date}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    )}
                </Container>
            </div>

            {showPermissionModal && showModal ? (
                <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show onlyClose />
            ) : (
                permissionAllowed && (
                    <Fragment>
                        {showModal.show === "addPayment" && (
                            <AddPaymentModal
                                show
                                onClose={() => setShowModal({ show: null })}
                                onCloseAndRefresh={handleCloseAndRefresh}
                                partID={barClientData.part_id}
                                barClientName={barClientData.participant_fullname}
                            />
                        )}
                        {showModal.show === "settleTheBill" && (
                            <SettleTheBillModal
                                show
                                onClose={() => setShowModal({ show: null })}
                                onCloseAndRefresh={handleCloseAndRefresh}
                                partID={barClientData.part_id}
                                barClientID={getBarClient.barClientID}
                                barClientName={barClientData.participant_fullname}
                                sum_orders_value={barClientData.sum_orders_value}
                                balance={barClientData.sum_bar_payment - barClientData.sum_orders_value}
                                paymentSum={barClientData.sum_bar_payment}
                            />
                        )}
                        {(showModal.show === "activateAgain" || showModal.show === "cancelOrder") && (
                            <ConfirmActionModal
                                show
                                onClose={() => setShowModal({ show: null })}
                                modalTxt={
                                    showModal.show === "activateAgain" ? (
                                        <p className="my-3 fs-5">
                                            {t("barClients.activateAgainText")}
                                            <b>{barClientData.participant_fullname}</b>?
                                        </p>
                                    ) : showModal.show === "cancelOrder" ? (
                                        <>
                                            <p className="my-3 fs-5">{t("barClients.cancelOrderTxt")}</p>
                                            <p className="m-0">{showModal.orderName}</p>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                                onProceed={() => {
                                    if (showModal.show === "activateAgain") setActivateBarClient(true);
                                    else if (showModal.show === "cancelOrder") setFetchCancelOrder(true);
                                }}
                            />
                        )}

                        {showModal.show === "blockTheClient" && (
                            <BlockBarClientModal
                                show
                                onClose={() => setShowModal({ show: null })}
                                modalTxt={
                                    <p className="my-3 fs-5">
                                        {t("barClients.blockTheClientText")}
                                        <b>{barClientData.participant_fullname}</b>?
                                    </p>
                                }
                                onProceed={() => setBlockBarClient(true)}
                            />
                        )}
                    </Fragment>
                )
            )}
        </Fragment>
    );
};

export default BarClientDetails;
