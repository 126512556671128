import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import Email from "../../forms/Email";

const RememberPass = (props) => {
    const { setBasicAlert, fetchURL, validateEmail } = useContext(BasicContext);
    const [email, setEmail] = useState("");
    const [doFetch, setDoFetch] = useState(false);

    const { t } = useTranslation();

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setBasicAlert(null);
        if (!email || !validateEmail(email)) {
            setBasicAlert({ variant: "danger", message: t("error.invalidEmail"), fading: false });
        } else {
            //console.log(email);
            setDoFetch(true);
        }
    };

    useEffect(() => {
        const resetPassword = async () => {
            setBasicAlert({ variant: "info", message: t("info.resettingPassword"), fading: false });

            try {
                const response = await fetch(`${fetchURL}/reset-password`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: email, lang: "pl" }),
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        else {
                            setBasicAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                            setEmail("");
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
                this.setState({ loading: false });
            }
        };
        if (doFetch) resetPassword();
        return () => {
            setDoFetch(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doFetch]);

    return (
        <Fragment>
            <Button variant="outline-primary" id="goBack" onClick={() => props.onSetSubPage("loginForm")}>
                {t("goBack")}
            </Button>
            <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                    <Form onSubmit={handleCheckValidation} id="rememberPassForm" autoComplete="off">
                        <Email value={email} onChangeValue={(e) => setEmail(e.target.value)} id="userEmail" name="email" />

                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" disabled={doFetch ? true : false} className="mt-3">
                                {t("user.resetPassword")}
                            </Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RememberPass;
