import React, { Fragment, useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import { LoginDataContext } from "../structure/LoggedPages";

const PermissionModal = (props) => {
    const { setBasicAlert, setQRscanner, QRscanner } = useContext(BasicContext);
    const { setCurrentPage } = useContext(LoginDataContext);
    const { t } = useTranslation();
    return (
        <Fragment>
            <Modal show={props.show}>
                <Modal.Body className="text-center">
                    <Row>
                        <Col xs={12}>
                            <p className="h3 my-3">{t("permissionModal.title")}</p>
                        </Col>
                        <Col xs={12}>
                            <p className="fs-5 my-3">
                                {t("permissionModal.text1")}
                                <strong>{t(`permissionModal.${props.option}`)}</strong>
                                {t("permissionModal.text2")}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <Button
                                variant="primary"
                                size="lg"
                                className="my-3 d-inline-block"
                                onClick={() => {
                                    setBasicAlert(null);
                                    window.scrollTo(0, 0);
                                    setQRscanner({ ...QRscanner, show: true });
                                }}
                            >
                                {t("ident.scanQRCode")}
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button
                                variant="outline-secondary"
                                className="my-3 d-inline-block"
                                onClick={() => {
                                    setBasicAlert(null);
                                    props.onClose();
                                    if (!props.onlyClose) setCurrentPage({ nav: "sell", subpage: "sellMain", params: "" });
                                }}
                            >
                                {t("cancel")}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default PermissionModal;
