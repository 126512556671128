import { faDollarSign, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import SubmitOrderModal from "../../modals/SubmitOrderModal";
import { LoginDataContext } from "../../structure/LoggedPages";

const BarItemsCart = () => {
    const { activeEvent, setBasicAlert, fetchURL } = useContext(BasicContext);
    const { cart, setCart, setNextPage } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [showSellModal, setShowSellModal] = useState(false);
    const [submitCashSell, setSubmitCashSell] = useState(false);

    useEffect(() => {
        const insertCashSell = async () => {
            const passData = {
                activeEvent: activeEvent,
                cart: cart,
            };
            try {
                const response = await fetch(`${fetchURL}/insert-cash-sell`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.submitOrder`), fading: 800 });
                            setShowSellModal(false);
                            setCart([]);
                        }
                    });
                }
            } catch (err) {
                //console.log(err);
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (submitCashSell) insertCashSell();

        return () => {
            setSubmitCashSell(false);
        };
    }, [submitCashSell]);

    const cartSum = () => {
        let sum = 0,
            sumIngame = 0;
        cart.forEach((e) => {
            sum += e.price_amount;
            sumIngame += e.ingame_price_amount;
        });

        sum = sum.toString().replace(".", ",");
        sumIngame = sumIngame.toString().replace(".", ",");

        return `${sum} ${t("currency")} ${sumIngame > 0 ? `${sumIngame} ${t("caps")}` : ""}`;
    };

    const addItem = (item) => {
        let tmpCart = [...cart];
        tmpCart.forEach((e) => {
            if (e.bar_item_code === item.bar_item_code) {
                e.amount += 1;
                e.price_amount = e.amount * e.price;
                e.ingame_price_amount = e.amount * e.ingame_price;
            }
        });
        setCart(tmpCart);
    };

    const subtractItem = (item) => {
        let tmpCart = [...cart],
            deleteIndex = null;
        tmpCart.forEach((e, i) => {
            if (e.bar_item_code === item.bar_item_code) {
                e.amount -= 1;
                //console.log(e.amount);
                if (e.amount <= 0) deleteIndex = i;
                else {
                    e.price_amount = e.amount * e.price;
                    e.ingame_price_amount = e.amount * e.ingame_price;
                }
            }
        });
        //console.log(deleteIndex);
        if (deleteIndex !== null) tmpCart.splice(deleteIndex, 1);

        setCart(tmpCart);
    };

    return (
        <Fragment>
            <Col xs={12} md={3} id="barItemCart">
                <Row id="cart">
                    <Col xs={12}>
                        <Table size="sm">
                            <tbody>
                                {cart.map((e, i) => (
                                    <Fragment key={i}>
                                        <tr>
                                            <td>
                                                {e.amount}x <b>{e.bar_item_name}</b>
                                            </td>
                                            <td className="text-end">
                                                {e.price_amount.toString().replace(".", ",")} {t("currency")}{" "}
                                                {e.ingame_price > 0 && `${e.ingame_price_amount.toString().replace(".", ",")} ${t("caps")}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <Button variant="outline-secondary" className="px-4 me-5 py-1" onClick={() => addItem(e)}>
                                                    +1
                                                </Button>
                                                <Button variant="outline-secondary" className="px-4 py-1" onClick={() => subtractItem(e)}>
                                                    -1
                                                </Button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-2">
                        <hr />
                        <Table id="cartSum">
                            <tbody>
                                <tr>
                                    <td>{t("barCart.sum")}:</td>
                                    <td className="text-end">{cartSum()}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} md={6} id="barItemCartCol" className="text-center">
                        <Button variant="outline-primary" className="bar-cart" disabled={cart.length === 0} onClick={() => setShowSellModal(true)}>
                            <FontAwesomeIcon icon={faDollarSign} />
                            {t("barCart.sell")}
                        </Button>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button
                            variant="outline-primary"
                            className="bar-cart"
                            id="tickBtn"
                            disabled={cart.length === 0}
                            onClick={() => setNextPage({ nav: "sell", subpage: "addToCredit", params: "" })}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                            {t("barCart.tick")}
                        </Button>
                    </Col>
                    <Col xs={12} className="text-center mt-4">
                        <Button variant="outline-secondary" id="eraseCart" onClick={() => setCart([])} disabled={cart.length === 0}>
                            {t("erase")}
                        </Button>
                    </Col>
                </Row>
            </Col>

            {showSellModal && (
                <SubmitOrderModal
                    show
                    onClose={() => setShowSellModal(false)}
                    onSubmitOrder={() => {
                        setBasicAlert({ variant: "info", message: t("info.submitOrder") });
                        setSubmitCashSell(true);
                    }}
                    onClearOrder={() => {
                        setCart([]);
                        setShowSellModal(false);
                    }}
                />
            )}
        </Fragment>
    );
};

export default BarItemsCart;
