import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { BasicContext } from "../../App";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const BarItemCategoriesDropdown = (props) => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [barItemCategoriesList, setBarItemCategoriesList] = useState(null);

    useEffect(() => {
        const getBarItemCategories = async () => {
            try {
                const response = await fetch(`${fetchURL}/bar-item-categories`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBarItemCategoriesList(fetchedData.list);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (loading) getBarItemCategories();

        return () => {
            setLoading(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    return (
        <Fragment>
            <Form.Label>
                {t("barItems.categories.name")} <span className="required">*</span>
            </Form.Label>
            {loading ? (
                <Row>
                    <Col className="text-center">
                        <LoadingProgressBar />
                    </Col>
                </Row>
            ) : barItemCategoriesList.length === 0 ? (
                <Row>
                    <Col>
                        <Alert variant="info" className="alert-small">
                            <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                            {t("info.noBarItemCategories")}
                        </Alert>
                    </Col>
                </Row>
            ) : (
                <Dropdown
                    placeholder={t("searchAndSelect")}
                    fluid
                    selection
                    options={barItemCategoriesList}
                    onChange={(event, { value }) => {
                        props.onSelectBarItemCat(value);
                    }}
                    className="mb-3"
                    id="barItemCategoriesList"
                    value={props.selectedBarItemCat}
                />
            )}
        </Fragment>
    );
};

export default BarItemCategoriesDropdown;
