import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import DescPopover from "../popovers/DescPopover";

const TextForm = (props) => {
    const { value, onChangeValue, req, labelTxt, placeholderTxt, name, helper, id, readOnly, size } = props;
    return (
        <Fragment>
            <Form.Group>
                {labelTxt && (
                    <Form.Label>
                        {labelTxt} {req && <span className="required">*</span>}
                        {helper && <DescPopover popoverTxt={<p>{helper}</p>} id={`${id}Popover`} />}
                    </Form.Label>
                )}
                <Form.Control
                    type="text"
                    placeholder={placeholderTxt}
                    name={name}
                    id={id}
                    value={value}
                    onChange={(e) => {
                        document.getElementById(id).classList.remove("is-invalid");
                        onChangeValue(e);
                    }}
                    className={req ? "req" : ""}
                    readOnly={readOnly ? true : false}
                    size={size ? size : "lg"}
                />
            </Form.Group>
        </Fragment>
    );
};

export default TextForm;
