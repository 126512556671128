import React, { createContext, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import LoadingSpinner from "./components/spinners/LoadingSpinner";
import LoggedPages from "./components/structure/LoggedPages";
import NotLoggedPages from "./components/structure/NotLoggedPages";
import ShowQRCode from "./components/other/ShowQRCode";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import SessionAlert from "./components/alerts/SessionAlert";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, faTwitter, faFontAwesome);

var fetchURL = process.env.REACT_APP_API_URL;
var moduleName = process.env.REACT_APP_MODULE_NAME;
//var frontURL = process.env.PUBLIC_URL;
var interval;

export const BasicContext = createContext();

const App = () => {
    const { t } = useTranslation();
    const [basicAlert, setBasicAlert] = useState(null);
    const [sessionAlert, setSessionAlert] = useState(null);
    const [QRscanner, setQRscanner] = useState({ show: false, height: window.outerHeight });
    const [QRData, setQRData] = useState(null);
    const [loginData, setLoginData] = useState({ loggedIn: false, disallowPermission: false, userPrivileges: [], cookieExpDate: null });
    const [loading, setLoading] = useState(true);
    const [logout, setLogout] = useState(false);
    const [activeEvent, setActiveEvent] = useState(null);
    const [checkActiveEvent, setCheckActiveEvent] = useState(false);

    useEffect(() => {
        const getActiveEvent = async (userPrivileges, cookieExpDate) => {
            try {
                const response = await fetch(`${fetchURL}/check-active-event`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.status}`), fading: null });
                            setLoginData({ ...loginData, disallowPermission: true, loggedIn: false });
                        } else if (fetchedData.status === false) {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setLoginData({ ...loginData, disallowPermission: true, loggedIn: false });
                        } else {
                            setActiveEvent(fetchedData.status === "noActiveEvent" ? fetchedData.status : fetchedData.activeEventCode);
                            setLoginData({ ...loginData, loggedIn: true, userPrivileges: userPrivileges, cookieExpDate: cookieExpDate });
                        }
                        setLoading(false);
                        setCheckActiveEvent(false);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (checkActiveEvent) getActiveEvent(checkActiveEvent.userPrivileges, checkActiveEvent.cookieExpDate);

        return () => {
            setCheckActiveEvent(false);
        };
    }, [checkActiveEvent]);

    useEffect(() => {
        const fetchMainData = async () => {
            try {
                const response = await fetch(`${fetchURL}/`, {
                    credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setLoginData({ ...loginData, disallowPermission: true, loggedIn: false });
                        } else if (fetchedData.status && fetchedData.loginSuccess) {
                            setCheckActiveEvent({ userPrivileges: fetchedData.userPrivileges, cookieExpDate: fetchedData.cookieExpDate });
                        } else if (fetchedData.status && !fetchedData.loginSuccess) {
                            setLoading(false);
                            setLoginData({ ...loginData, loggedIn: false });
                        } else {
                            setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")}`, fading: null });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        fetchMainData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //console.log("loginData.loggin changed");
        const autoLogout = async () => {
            try {
                const response = await fetch(`${fetchURL}/logout`, {
                    credentials: "include",
                    method: "POST",
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: t("error.loggingOutFailed") + err, fading: null });
            }
        };

        const startSession = () => {
            //console.log("start session");
            //console.log("cookieExpDate:", loginData.cookieExpDate);
            const loggedTime = Date.now();
            const endSessTime = loginData.cookieExpDate; //czas w ms kiedy ma zakończyć się sesja

            //jeśli została strona odświeżona w trakcie trwania sesji a także gdy do końca trwania sesji pozostało mniej niż 10 sekund -> wyświetl komunikat

            if (endSessTime - loggedTime <= 10000)
                if (!sessionAlert) {
                    const secLeft = Math.round((endSessTime - loggedTime) / 1000);
                    setSessionAlert({
                        message: `${t("session.in")} ${secLeft} ${secLeft <= 4 && secLeft >= 2 ? t("session.seconds2") : secLeft === 1 ? t("session.seconds1") : t("session.secondsMore")} ${t(
                            "session.sessionWillExpire"
                        )}`,
                        dismissible: false,
                    });
                }

            interval = setInterval(() => {
                const now = Date.now();
                const leftTime = endSessTime - now;
                let secLeft = Math.round(leftTime / 1000);
                if (leftTime <= 0) {
                    //sesja wygasła
                    setBasicAlert(null);
                    setSessionAlert({ message: t("session.sessionExpired"), dismissible: true });
                    //console.log("logout");
                    autoLogout();
                    setLoginData({ ...loginData, loggedIn: false, cookieExpDate: null });
                } else if (leftTime <= 10000)
                    setSessionAlert({
                        message:
                            leftTime > 1000
                                ? `${t("session.in")} ${secLeft} ${secLeft <= 4 && secLeft >= 2 ? t("session.seconds2") : secLeft === 1 ? t("session.seconds1") : t("session.secondsMore")} ${t(
                                      "session.sessionWillExpire"
                                  )}`
                                : t("session.loggingOut"),
                        dismissible: false,
                    });
                else if (leftTime <= 120000 && leftTime > 10000) {
                    //sesja jeszcze trwa, ale zostało więcej niż 10 sekund
                    setSessionAlert({
                        message: leftTime < 60000 ? t("session.lessThanMinute") : t("session.moreThanMinute"),
                        dismissible: false,
                    });
                }
            }, 1000);
        };

        if (!loginData.loggedIn) $("#showNotLoggedPages").fadeIn(700);
        else $("#showNotLoggedPages").fadeOut();

        if (loginData.cookieExpDate) startSession();
        else clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData.loggedIn]);

    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleCheckUserPrivilage = (priv) => {
        for (let i = 0; i < loginData.userPrivileges.length; i++) if (loginData.userPrivileges[i] === priv) return true;
        return false;
    };

    useEffect(() => {
        const handleLogout = async () => {
            try {
                const response = await fetch(`${fetchURL}/logout`, {
                    credentials: "include",
                    method: "POST",
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false) setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: 2000 });
                        else {
                            setSessionAlert(null);
                            setLoginData({ ...loginData, loggedIn: false, cookieExpDate: null });
                            setBasicAlert({ variant: "success", message: t(`success.logout`), fading: 2000 });
                            $("#showNotLoggedPages").fadeIn(700);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: t("error.loggingOutFailed") + err, fading: null });
            }
        };
        if (logout) handleLogout();

        return () => setLogout(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout]);

    useEffect(() => {
        const alertTimeout = (fading) => {
            setTimeout(() => {
                //setBasicAlert(null);
            }, fading + 400);
        };

        if (basicAlert) {
            $(".alert-basic").fadeIn(0);
            if (basicAlert.fading) {
                $(".alert-basic")
                    .delay(basicAlert.fading)
                    .fadeOut(400);
                alertTimeout(basicAlert.fading);
            }
        } else {
            clearTimeout(alertTimeout(null));
        }
    }, [basicAlert]);

    const toggleFullScreen = () => {
        document.documentElement.requestFullscreen();
        document.getElementById("fullscreenBtn").classList.add("d-none");
    };

    return (
        <Fragment>
            <Button variant="outline-secondary" onClick={() => toggleFullScreen()} id="fullscreenBtn">
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
            </Button>

            <BasicContext.Provider
                value={{
                    setBasicAlert,
                    fetchURL,
                    moduleName,
                    QRscanner,
                    setQRscanner,
                    validateEmail,
                    setLoginData,
                    QRData,
                    setQRData,
                    handleCheckUserPrivilage,
                    loginData,
                    setLogout,
                    activeEvent,
                    setSessionAlert,
                    setCheckActiveEvent,
                }}
            >
                {basicAlert && (
                    <Row>
                        <Col>
                            <Alert className="alert-basic" variant={basicAlert.variant} onClose={() => setBasicAlert("")} dismissible style={{ display: "none" }}>
                                {basicAlert.message}
                            </Alert>
                        </Col>
                    </Row>
                )}
                {sessionAlert && <SessionAlert sessionAlert={sessionAlert} onCloseAlert={setSessionAlert} />}
                {loading && <LoadingSpinner />}
                {QRscanner.show && <ShowQRCode />}
                {loginData.loggedIn ? (
                    <LoggedPages />
                ) : (
                    <div id="showNotLoggedPages" style={{ display: "none" }}>
                        <NotLoggedPages />
                    </div>
                )}
            </BasicContext.Provider>
        </Fragment>
    );
};

export default App;
