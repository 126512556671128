import React, { Fragment, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoginDataContext } from "../structure/LoggedPages";

const ConfirmEscapeModal = () => {
    const { setConfirmEscape, confirmEscape } = useContext(LoginDataContext);
    const { t } = useTranslation();
    return (
        <Fragment>
            <Modal show={confirmEscape.show} onHide={() => setConfirmEscape({ show: false, action: null })}>
                <Modal.Header>
                    <Modal.Title>{t("confirmEscapeModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("confirmEscapeModal.text")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" onClick={() => setConfirmEscape({ ...confirmEscape, show: false })}>
                        {t("confirmEscapeModal.yes")}
                    </Button>
                    <Button variant="primary" type="button" onClick={() => setConfirmEscape({ show: false, action: null })}>
                        {t("confirmEscapeModal.no")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ConfirmEscapeModal;
