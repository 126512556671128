import React from "react";
import { useTranslation } from "react-i18next";

const LoadingProgressBar = () => {
    const { t } = useTranslation();
    return (
        <div className="text-center">
            <p className="loading-data">{t("loadingData")}</p>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingProgressBar;
