import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Password = (props) => {
    const { value1, value2, onChangeValue, option, arrangement } = props;
    const { t } = useTranslation();
    return (
        <Row>
            <Col md={arrangement === "block" || !option || option === "single" ? 12 : 6} xs={12}>
                <Form.Label>
                    {t("form.pass")} <span className="required"> *</span>
                </Form.Label>
                <Form.Control
                    type="password"
                    id="password"
                    placeholder={t("form.typePass")}
                    name="password"
                    value={value1}
                    onChange={(e) => {
                        onChangeValue(e);
                        document.getElementById("password").classList.remove("is-invalid");
                    }}
                    autoComplete={value2 ? "new-password" : "nope"}
                    className="mb-3 req"
                    size="lg"
                />
            </Col>
            {option === "double" && (
                <Col md={arrangement === "block" ? 12 : 6} xs={12}>
                    <Form.Label>
                        {t("form.repeatPass")} <span className="required"> *</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        id="repeatPassword"
                        placeholder={t("form.typeRepeatPass")}
                        name="repeatPassword"
                        value={value2}
                        onChange={(e) => {
                            onChangeValue(e);
                            document.getElementById("repeatPassword").classList.remove("is-invalid");
                        }}
                        autoComplete={"new-password"}
                        className="mb-3 req"
                        size="lg"
                    />
                </Col>
            )}
        </Row>
    );
};

export default Password;
