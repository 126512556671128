import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const ShowIconsListModal = (props) => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();
    const [iconsList, setIconsList] = useState(null);
    const [loading, setloading] = useState(true);
    const [selectedIcon, setSelectedIcon] = useState({ blobID: null });

    useEffect(() => {
        const getIcons = async () => {
            try {
                const response = await fetch(`${fetchURL}/bar-item-icons`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            const iconsList = fetchedData.iconsList,
                                convertedIcons = [];
                            iconsList.forEach((e) => {
                                const blob = e.blob_data;
                                const buffer = Buffer.from(blob);
                                const bufferBase64 = buffer.toString("base64");
                                convertedIcons.push({
                                    data: bufferBase64,
                                    type: e.blob_file_type,
                                    name: e.blob_name,
                                    blobID: e.blob_id,
                                });
                            });
                            //console.log(convertedIcons);
                            setIconsList(convertedIcons);
                            setloading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        getIcons();
    }, []);

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.onClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t("iconsListModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>{t("iconsListModal.text")}</p>
                        </Col>
                    </Row>
                    {loading ? (
                        <Row>
                            <Col className="text-center">
                                <LoadingProgressBar />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            {iconsList.length === 0 ? (
                                <Col>
                                    <Alert variant="info" className="alert-small">
                                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                        {t("iconsListModal.noIcons")}
                                    </Alert>
                                </Col>
                            ) : (
                                iconsList.map((e, i) => (
                                    <Col xs={4} md={3} lg={2} key={i}>
                                        <Button
                                            variant="outline-secondary"
                                            size="lg"
                                            className={`icons-tile-btn my-2${e.blobID === selectedIcon.blobID ? " selected" : ""}`}
                                            onClick={() => setSelectedIcon(e)}
                                        >
                                            <img src={`data:${e.type};base64,${e.data}`} alt={e.name} />
                                        </Button>
                                    </Col>
                                ))
                            )}
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" onClick={() => props.onClose()} className="me-3">
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" type="button" disabled={!selectedIcon.blobID} onClick={() => props.onChooseIcon(selectedIcon)}>
                        {t("iconsListModal.chooseBtn")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ShowIconsListModal;
