import React, { Fragment, useContext } from "react";
import { LoginDataContext } from "../../structure/LoggedPages";
import ScheduleTable from "./ScheduleTable";
import EditSchedule from "./EditSchedule";

const Duties = () => {
    const { currentPage } = useContext(LoginDataContext);
    return (
        <Fragment>
            {currentPage.subpage === "dutiesTable" && <ScheduleTable />}
            {currentPage.subpage === "editSchedule" && <EditSchedule />}
        </Fragment>
    );
};

export default Duties;
