import React, { Fragment, useContext } from "react";
import { Button } from "react-bootstrap";
import { LoginDataContext } from "../structure/LoggedPages";

const BtnNav = (props) => {
    const { navName, title, pageName, activePage } = props;

    const { setCurrentPage } = useContext(LoginDataContext);

    return (
        <Fragment>
            <Button
                name={navName}
                variant="link"
                disabled={activePage === navName ? true : false}
                className={activePage === navName ? "nav-link active" : "nav-link"}
                id={`${navName}Nav`}
                type="button"
                onClick={() => {
                    setCurrentPage({ nav: navName, subpage: pageName, params: "" });
                }}
            >
                {title}
            </Button>
        </Fragment>
    );
};

export default BtnNav;
