import { faCircleInfo, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
import { BasicContext } from "../../../App";
import NoActiveEventAlert from "../../alerts/NoActiveEventAlert";
import AmountLimit from "../../forms/AmountLimit";
import Email from "../../forms/Email";
import StartingPayment from "../../forms/StartingPayment";
import TextareaForm from "../../forms/TextareaForm";
import TextForm from "../../forms/TextForm";
import NavDetails from "../../navigation/NavDetails";
import UsersListForBarClients from "../../forms/UsersListForBarClients";
import { LoginDataContext } from "../../structure/LoggedPages";
import PermissionModal from "../../modals/PermissionModal";
import AmountFree from "../../forms/AmountFree";

const newPersonDataStart = {
        userID: null,
        user_email: "",
        user_name: "",
        user_surname: "",
        user_nickname: "",
        user_phone: "",
        user_stat_code: "NEW",
        user_rodo_agreement: false,
        legalAge: false,
        identValue: null,
        comment: "",
        partHasQR: false,
    },
    existingPersonDataStart = {
        userID: null,
        partID: null,
        partHasQR: false,
        identValue: null,
        legalAge: false,
        rodoAgreement: false,
        partStatCode: null,
        hasCheckedRodo: true,
        hasCheckedLegalAge: true,
        comment: "",
    },
    barClientDataStart = {
        startingPayment: "",
        amountLimit: 0,
        amountFree: 0,
        noLimit: false,
        ifInnerAccount: false,
    };

const AddBarClient = () => {
    const { setBasicAlert, activeEvent, fetchURL, setQRscanner, QRscanner, QRData, setQRData, validateEmail } = useContext(BasicContext);
    const { currentPage, setChangedValuesInFormes, setLoggedUserQR, loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(false);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [userMiniAlert, setUserMiniAlert] = useState(null);
    const [addNewBarClient, setAddNewBarClient] = useState(false);

    const [newPersonData, setNewPersonData] = useState(newPersonDataStart);
    const [existingPersonData, setExistingPersonData] = useState(existingPersonDataStart);
    const [barClientData, setBarClientData] = useState(barClientDataStart);

    const [showPermissionModal, setShowPermissionModal] = useState("MANAG");
    const [permissionAllowed, setPermissionAllowed] = useState(false);

    useEffect(() => {
        setLoggedUserQR(null);
    }, []);

    const clearForms = () => {
        setDisableSubmitting(false);
        setUserMiniAlert(null);
        setNewPersonData(newPersonDataStart);
        setExistingPersonData(existingPersonDataStart);
        setBarClientData(barClientDataStart);
    };

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setPermissionAllowed(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        const checkIdentAssignment = async () => {
            const passData = {
                value: QRData,
            };
            try {
                const response = await fetch(`${fetchURL}/check-assigned-ident`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission" || fetchedData.status === "assigned") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            if (selectedOption === "chooseExistingAccount") setExistingPersonData({ ...existingPersonData, identValue: QRData });
                            else setNewPersonData({ ...newPersonData, identValue: QRData });
                            //console.log(existingPersonData);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };

        if (QRData && permissionAllowed) checkIdentAssignment();
        else if (QRData && !permissionAllowed) checkPermission();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    useEffect(() => {
        if (selectedOption) {
            clearForms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const handleCheckValidation = (e) => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        e.preventDefault();
        const errorMsg = [];
        if (selectedOption === "chooseExistingAccount") {
            if (!existingPersonData.userID) {
                errorMsg.push(t("error.noSelectedUser"));
                document.getElementById("usersList").classList.add("is-invalid");
            }
            if (userMiniAlert) if (userMiniAlert.variant === "danger") errorMsg.push(userMiniAlert.message);
            if (!existingPersonData.identValue) {
                errorMsg.push(t("error.noScannedQRCode"));
            }
        } else if (selectedOption === "createAccount") {
            if (!newPersonData.user_email) {
                errorMsg.push(t("error.emptyEmail"));
                document.getElementById("barClientEmail").classList.add("is-invalid");
            } else if (!validateEmail(newPersonData.user_email)) {
                errorMsg.push(t("error.invalidEmail"));
                document.getElementById("barClientEmail").classList.add("is-invalid");
            }

            if (!newPersonData.user_name) {
                errorMsg.push(t("error.emptyName"));
                document.getElementById("barClientName").classList.add("is-invalid");
            }
            if (!newPersonData.user_surname) {
                errorMsg.push(t("error.emptySurname"));
                document.getElementById("barClientSurname").classList.add("is-invalid");
            }
            if (!newPersonData.user_phone) {
                errorMsg.push(t("error.emptyPhone"));
                document.getElementById("barClientPhone").classList.add("is-invalid");
            }
            if (!newPersonData.identValue) {
                errorMsg.push(t("error.noScannedQRCode"));
            }
        }
        if (!barClientData.amountFree && barClientData.amountFree !== 0) {
            errorMsg.push(t("error.emptyAmountFree"));
            document.getElementById("amountFree").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingNewBarClient") });
            setAddNewBarClient(true);
        }
    };

    useEffect(() => {
        //dodawanie nowego klienta barowego - musi istnieć co najmniej użytkownik, jeśli nie ma uczestnika - zostanie on automatycznie utworzony
        const addBarClient = async () => {
            const passData = {
                personData: selectedOption === "chooseExistingAccount" ? existingPersonData : newPersonData,
                barClientData: barClientData,
                activeEvent: activeEvent,
                loggedUserQR: loggedUserQR,
            };
            //console.log(passData);
            try {
                const response = await fetch(`${fetchURL}/add-bar-client`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                            setSelectedOption(false);
                            clearForms();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };
        if (addNewBarClient) addBarClient();
        return () => {
            setAddNewBarClient(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewBarClient]);

    const onChangeUserData = (e) => {
        setNewPersonData({ ...newPersonData, [e.target.name]: e.target.value });
        setDisableSubmitting(false);
    };

    useEffect(() => {
        if (selectedOption === "chooseExistingAccount") {
            if (existingPersonData.partHasBarClient) setUserMiniAlert({ variant: "danger", message: t("barClients.partAlreadyHasBarClient") });
            else if (!existingPersonData.partID) setUserMiniAlert({ variant: "info", message: t("barClients.userHasNoPart") });
            else if (existingPersonData.partStatCode === "ABSENT") setUserMiniAlert({ variant: "danger", message: t("barClients.partIsAbsent") });
            else if (existingPersonData.partStatCode === "KICKEDOUT") setUserMiniAlert({ variant: "danger", message: t("barClients.partIsKickedOut") });
            else if (existingPersonData.partStatCode === "ABORTED") setUserMiniAlert({ variant: "danger", message: t("barClients.partIsAborted") });
            else setUserMiniAlert(null);
        } else setUserMiniAlert(null);
    }, [existingPersonData]);

    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />
            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show />}
            <Fragment>
                {activeEvent === "noActiveEvent" ? (
                    <NoActiveEventAlert alertTxt={currentPage.subpage} />
                ) : (
                    permissionAllowed && (
                        <Form onSubmit={handleCheckValidation} autoComplete="off">
                            <div className="scrolled-content">
                                <Container>
                                    <Row>
                                        <Col lg={{ offset: 2, span: 8 }} xs={12} className="mb-4">
                                            <Row>
                                                <Col xs={5} className="text-center">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="lg"
                                                        className="my-3"
                                                        onClick={() => {
                                                            setSelectedOption("chooseExistingAccount");
                                                            setChangedValuesInFormes(true);
                                                        }}
                                                    >
                                                        {t("barClients.chooseExistingAccount")}
                                                    </Button>
                                                    <p className="mb-3">{t("barClients.chooseExistingAccountTxt")}</p>
                                                </Col>
                                                <Col xs={2} className="mt-4 text-center">
                                                    {t("or")}
                                                </Col>
                                                <Col xs={5} className="text-center">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="lg"
                                                        className="my-3"
                                                        onClick={() => {
                                                            setChangedValuesInFormes(true);
                                                            setDisableSubmitting(false);
                                                            setSelectedOption("createAccount");
                                                            setExistingPersonData({ ...existingPersonData, legalAge: false });
                                                        }}
                                                    >
                                                        {t("barClients.createAccount")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {selectedOption === "chooseExistingAccount" && (
                                        <Fragment>
                                            <Row>
                                                <Col xs={6}>
                                                    <UsersListForBarClients
                                                        onSetSelectedUser={(value) => {
                                                            setExistingPersonData(value);
                                                            setDisableSubmitting(false);
                                                        }}
                                                    />
                                                    {userMiniAlert && (
                                                        <Alert variant={userMiniAlert.variant} className="alert-small mt-2">
                                                            <FontAwesomeIcon icon={userMiniAlert.variant === "info" ? faCircleInfo : faExclamationCircle} className="mr-2" /> {userMiniAlert.message}
                                                        </Alert>
                                                    )}
                                                </Col>
                                                {existingPersonData.userID && (
                                                    <Fragment>
                                                        {(!userMiniAlert || (userMiniAlert && userMiniAlert.variant !== "danger")) && (
                                                            <Fragment>
                                                                <Col xs={6}>
                                                                    <Form.Label>
                                                                        {t("ident.qrCode")} <span className="required">*</span>
                                                                    </Form.Label>

                                                                    {existingPersonData.identValue && <p>{t("ident.identScanned")}</p>}
                                                                    {!existingPersonData.partHasQR && (
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            size="lg"
                                                                            className="d-block mb-3"
                                                                            onClick={() => {
                                                                                setDisableSubmitting(false);
                                                                                setBasicAlert(null);
                                                                                setQRscanner({ ...QRscanner, show: true });
                                                                            }}
                                                                        >
                                                                            {t(!existingPersonData.identValue ? "ident.scanQRCode" : "ident.scanNewCode")}
                                                                        </Button>
                                                                    )}
                                                                </Col>
                                                                <Col xs={12} lg={6}>
                                                                    <TextareaForm
                                                                        value={existingPersonData.comment}
                                                                        onChangeValue={(e) => {
                                                                            setExistingPersonData({ ...existingPersonData, comment: e.target.value });
                                                                            setDisableSubmitting(false);
                                                                        }}
                                                                        labelTxt={t("barClients.commentLabel")}
                                                                        name="comment"
                                                                        placeholderTxt={t("barClients.commentPlaceholder")}
                                                                        id="barClientPartComment"
                                                                    />
                                                                </Col>
                                                                {!existingPersonData.hasCheckedRodo && (
                                                                    <Col xs={12}>
                                                                        <Checkbox
                                                                            className="my-3"
                                                                            id="rodoCheckbox"
                                                                            label={t("user.rodo")}
                                                                            onClick={(e, data) => {
                                                                                setExistingPersonData({ ...existingPersonData, rodoAgreement: data.checked });
                                                                                setDisableSubmitting(false);
                                                                            }}
                                                                            checked={existingPersonData.rodoAgreement}
                                                                        />
                                                                    </Col>
                                                                )}
                                                                {!existingPersonData.hasCheckedLegalAge && (
                                                                    <Col xs={12}>
                                                                        <Checkbox
                                                                            className="my-3"
                                                                            id="legalAgeCheckbox"
                                                                            label={t("participants.legalAge")}
                                                                            onClick={(e, data) => {
                                                                                setExistingPersonData({ ...existingPersonData, legalAge: data.checked });
                                                                                setDisableSubmitting(false);
                                                                            }}
                                                                            checked={existingPersonData.legalAge}
                                                                        />
                                                                    </Col>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Row>
                                        </Fragment>
                                    )}
                                    {selectedOption === "createAccount" && (
                                        <Row>
                                            <Col xs={4}>
                                                <Email value={newPersonData.user_email} onChangeValue={onChangeUserData} id="barClientEmail" name="user_email" />
                                            </Col>
                                            <Col xs={3}>
                                                <TextForm
                                                    value={newPersonData.user_name}
                                                    onChangeValue={onChangeUserData}
                                                    req
                                                    labelTxt={t("user.nameLabel")}
                                                    placeholderTxt={t("user.namePlaceholder")}
                                                    name="user_name"
                                                    id="barClientName"
                                                />
                                            </Col>
                                            <Col xs={5}>
                                                <TextForm
                                                    value={newPersonData.user_surname}
                                                    onChangeValue={onChangeUserData}
                                                    req
                                                    labelTxt={t("user.surnameLabel")}
                                                    placeholderTxt={t("user.surnamePlaceholder")}
                                                    name="user_surname"
                                                    id="barClientSurname"
                                                />
                                            </Col>
                                            <Col xs={3}>
                                                <TextForm
                                                    value={newPersonData.user_nickname}
                                                    onChangeValue={onChangeUserData}
                                                    labelTxt={t("user.nicknameLabel")}
                                                    placeholderTxt={t("user.nicknamePlaceholder")}
                                                    name="user_nickname"
                                                    id="barClientNickname"
                                                    helper={t("user.nicknameHelper")}
                                                />
                                            </Col>
                                            <Col xs={4}>
                                                <TextForm
                                                    value={newPersonData.user_phone}
                                                    onChangeValue={onChangeUserData}
                                                    req
                                                    labelTxt={t("user.phoneLabel")}
                                                    placeholderTxt={t("user.phonePlaceholder")}
                                                    name="user_phone"
                                                    id="barClientPhone"
                                                />
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label>
                                                    {t("ident.qrCode")} <span className="required">*</span>
                                                </Form.Label>
                                                {newPersonData.identValue && <p>{newPersonData.identValue}</p>}
                                                <Button
                                                    variant="outline-primary"
                                                    size="lg"
                                                    className="d-block mb-3"
                                                    onClick={() => {
                                                        setDisableSubmitting(false);
                                                        setBasicAlert(null);
                                                        setQRscanner({ ...QRscanner, show: true });
                                                    }}
                                                >
                                                    {t("ident.scanQRCode")}
                                                </Button>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <TextareaForm
                                                    value={newPersonData.comment}
                                                    onChangeValue={onChangeUserData}
                                                    labelTxt={t("barClients.commentLabel")}
                                                    name="comment"
                                                    placeholderTxt={t("barClients.commentPlaceholder")}
                                                    id="barClientComment"
                                                />
                                            </Col>

                                            <Col xs={12}>
                                                <Checkbox
                                                    className="my-3"
                                                    id="rodoCheckbox"
                                                    label={t("user.rodo")}
                                                    onClick={(e, data) => {
                                                        setNewPersonData({ ...newPersonData, user_rodo_agreement: data.checked });
                                                        setDisableSubmitting(false);
                                                    }}
                                                    checked={newPersonData.user_rodo_agreement}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <Checkbox
                                                    className="my-3"
                                                    id="legalAgeCheckbox"
                                                    label={t("participants.legalAge")}
                                                    onClick={(e, data) => {
                                                        setNewPersonData({ ...newPersonData, legalAge: data.checked });
                                                        setDisableSubmitting(false);
                                                    }}
                                                    checked={newPersonData.legalAge}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {(selectedOption === "createAccount" ||
                                        (selectedOption === "chooseExistingAccount" && existingPersonData.userID && (!userMiniAlert || (userMiniAlert && userMiniAlert.variant !== "danger")))) && (
                                        <Fragment>
                                            <Row>
                                                <Col xs={12}>
                                                    <Checkbox
                                                        className="mt-2 mb-4"
                                                        id="innerAccountCheckbox"
                                                        label={t("barClients.ifInnerAccount")}
                                                        onClick={(e, data) => {
                                                            setBarClientData({ ...barClientData, ifInnerAccount: data.checked });
                                                            setDisableSubmitting(false);
                                                        }}
                                                        checked={barClientData.ifInnerAccount}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6} lg={5}>
                                                    <StartingPayment
                                                        onChangeValue={(value) => {
                                                            setBarClientData({ ...barClientData, startingPayment: value });
                                                            setDisableSubmitting(false);
                                                        }}
                                                        value={barClientData.startingPayment}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6} lg={7}>
                                                    <AmountFree
                                                        onChangeValue={(value) => {
                                                            setBarClientData({ ...barClientData, amountFree: value });
                                                            setDisableSubmitting(false);
                                                        }}
                                                        value={barClientData.amountFree}
                                                        helper
                                                        req
                                                    />
                                                </Col>
                                                <Col xs={12} md={6} lg={7}>
                                                    <AmountLimit
                                                        onChangeValue={(value) => {
                                                            setBarClientData({ ...barClientData, amountLimit: value });
                                                            setDisableSubmitting(false);
                                                        }}
                                                        disabled={barClientData.noLimit ? true : false}
                                                        value={barClientData.amountLimit}
                                                        helper
                                                    />
                                                    <Button type="submit" variant="primary" size="xl" disabled={disableSubmitting}>
                                                        {t("add")}
                                                    </Button>
                                                </Col>
                                                <Col xs={12} md={6} lg={5}>
                                                    <Checkbox
                                                        className="my-3"
                                                        id="noLimitCheckbox"
                                                        label={t("barClients.noLimit")}
                                                        onClick={(e, data) => {
                                                            setBarClientData({ ...barClientData, noLimit: data.checked, amountLimit: data.checked ? "" : 0 });
                                                            setDisableSubmitting(false);
                                                        }}
                                                        checked={barClientData.noLimit}
                                                    />
                                                    {barClientData.noLimit && (
                                                        <Image src="images/no_limit.gif" alt="No no, no no no no, no no no no, No no there's no limit!" fluid style={{ maxHeight: "10rem" }} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )}
                                </Container>
                            </div>
                        </Form>
                    )
                )}
            </Fragment>
        </Fragment>
    );
};

export default AddBarClient;
