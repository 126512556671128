import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { OverlayTrigger, Button, Popover } from "react-bootstrap";

const DescPopover = (props) => {
    const { popoverTxt, popoverTitle, placement, id } = props;
    return (
        <Fragment>
            <OverlayTrigger
                trigger="click"
                placement={placement ? placement : "right"}
                overlay={
                    <Popover id={id}>
                        {popoverTitle && <Popover.Header as="h3">{popoverTitle}</Popover.Header>}
                        <Popover.Body>{popoverTxt}</Popover.Body>
                    </Popover>
                }
            >
                <Button variant="link" size="sm" className="p-0 mx-1">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
            </OverlayTrigger>
        </Fragment>
    );
};

export default DescPopover;
