import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoginDataContext } from "../structure/LoggedPages";

const NavDetails = (props) => {
    const { goToPreviousPage } = useContext(LoginDataContext);
    const { t } = useTranslation();
    //console.log(props.title);
    return (
        <Container fluid className="py-3 bar-nav-details">
            <Row>
                <Col xs={3}>
                    {props.backBtn && (
                        <Button variant="outline-primary" onClick={() => goToPreviousPage()}>
                            {t("goBack")}
                        </Button>
                    )}
                </Col>
                <Col xs={6} className="text-center">
                    <p className="h4 mt-2">{t(props.title)}</p>
                </Col>
                <Col xs={3} className="text-end">
                    {props.actionDropdown}
                </Col>
            </Row>
        </Container>
    );
};

export default NavDetails;
