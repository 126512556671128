import React from "react";
import { Alert } from "react-bootstrap";

const SessionAlert = (props) => {
    const { sessionAlert, onCloseAlert } = props;
    return (
        <Alert variant="dark" className="alert-session" onClose={() => onCloseAlert(null)} dismissible={sessionAlert.dismissible}>
            {sessionAlert.message}
        </Alert>
    );
};

export default SessionAlert;
