import React, { Fragment } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AddBarClientModal = (props) => {
    const { t } = useTranslation();

    const handleCheckValidation = (e) => {
        e.preventDefault();
    };

    return (
        <Fragment>
            <Form onSubmit={handleCheckValidation} autoComplete="off">
                <Modal show={props.show} onHide={props.onClose} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{t("barNavbar.addBarClient")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body></Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" type="button" size="lg" className="me-3">
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="submit" size="lg">
                            {t("add")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </Fragment>
    );
};

export default AddBarClientModal;
