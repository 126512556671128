import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
import { BasicContext } from "../../../App";
import NoActiveEventAlert from "../../alerts/NoActiveEventAlert";
import ChooseTileIcon from "../../forms/ChooseTileIcon";
import BarItemCategoriesDropdown from "../../forms/BarItemCategoriesDropdown";
import TextForm from "../../forms/TextForm";
import NavDetails from "../../navigation/NavDetails";
import { LoginDataContext } from "../../structure/LoggedPages";

const AddBarItem = () => {
    const { setBasicAlert, activeEvent, fetchURL } = useContext(BasicContext);
    const { currentPage, loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();
    const [barItem, setBarItem] = useState({ code: "", name: "", priority: "", selectedIcon: false, active: true, price: "", ingamePrice: "", innerPrice: "" });
    const [selectedBarItemCat, setSelectedBarItemCat] = useState(null);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [barItemIcon, setBarItemIcon] = useState(null);
    const [checkAndAdd, setCheckAndAdd] = useState(false);

    const handleChangeValue = (e) => {
        setBarItem({ ...barItem, [e.target.name]: e.target.value });
        document.getElementById([e.target.id]).classList.remove("is-invalid");
        setDisableSubmitting(false);
    };

    const handleCheckValidation = (e) => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        e.preventDefault();
        const errorMsg = [],
            regExpWhiteSpace = /^\s*$/,
            regNumber = /^\d+$/;

        if (!selectedBarItemCat) {
            errorMsg.push(t("error.barItemCategoryNotSelected"));
            document.getElementById("barItemCategoriesList").classList.add("is-invalid");
        }

        if (!barItem.code) {
            errorMsg.push(t("error.emptyBarItemCategoryCode"));
            document.getElementById("barItemCode").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItem.code) || barItem.code.length > 100) {
            errorMsg.push(t("error.invalidBarItemCategoryCode"));
            document.getElementById("barItemCode").classList.add("is-invalid");
        }

        if (!barItem.name) {
            errorMsg.push(t("error.emptyBarItemCategoryName"));
            document.getElementById("barItemName").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItem.name) || barItem.name.length > 255) {
            errorMsg.push(t("error.invalidBarItemCategoryName"));
            document.getElementById("barItemName").classList.add("is-invalid");
        }

        if (!barItem.priority) {
            errorMsg.push(t("error.emptyBarItemCategoryPriority"));
            document.getElementById("barItemPriority").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(barItem.priority) || barItem.priority.length > 11 || !regNumber.test(barItem.priority)) {
            errorMsg.push(t("error.invalidBarItemCategoryPriority"));
            document.getElementById("barItemPriority").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingNewBarItemCategory") });
            setCheckAndAdd(true);
        }
    };

    useEffect(() => {
        const insertNewBarItem = async (blobID) => {
            const passData = {
                barItem: barItem,
                category: selectedBarItemCat,
                blobID: blobID,
                activeEvent: activeEvent,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/insert-new-bar-item`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.addNewBarItem`), fading: 2000 });
                            setBarItem({ code: "", name: "", priority: "", selectedIcon: false, active: true, price: "", ingamePrice: "", innerPrice: "" });
                            setBarItemIcon(null);
                            setDisableSubmitting(true);
                            document.getElementById("addBarItemContainer").scroll({ top: 0, behavior: "smooth" });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        const insertBarItemIcon = async () => {
            const data = new FormData();
            data.append("file", barItemIcon);
            try {
                const response = await fetch(`${fetchURL}/add-bar-item-icon`, {
                    credentials: "include",
                    method: "POST",
                    body: data,
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            insertNewBarItem(fetchedData.blobID);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };

        const checkBarItemCodeUniqueness = async () => {
            const passData = {
                barItemCode: barItem.code,
            };
            try {
                const response = await fetch(`${fetchURL}/check-bar-item-code-uniqueness`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            if (barItem.selectedIcon) {
                                if (!barItemIcon.blobID) insertBarItemIcon();
                                else insertNewBarItem(barItemIcon.blobID);
                            } else insertNewBarItem(null);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (checkAndAdd) checkBarItemCodeUniqueness();

        return () => {
            setCheckAndAdd(false);
        };
    }, [checkAndAdd]);

    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />
            {!activeEvent || activeEvent === "noActiveEvent" ? (
                <NoActiveEventAlert alertTxt={currentPage.subpage} />
            ) : (
                <Form onSubmit={handleCheckValidation} autoComplete="off">
                    <div className="scrolled-content" id="addBarItemContainer">
                        <Container className="py-3">
                            <Row>
                                <Col lg={5} xs={12}>
                                    <BarItemCategoriesDropdown selectedBarItemCat={selectedBarItemCat} onSelectBarItemCat={(v) => setSelectedBarItemCat(v)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={5} xs={12}>
                                    <TextForm
                                        value={barItem.code}
                                        onChangeValue={handleChangeValue}
                                        req
                                        labelTxt={t("barItems.code")}
                                        placeholderTxt={t("barItems.codePlaceholder")}
                                        name="code"
                                        id="barItemCode"
                                    />
                                </Col>
                                <Col lg={7} xs={12}>
                                    <TextForm
                                        value={barItem.name}
                                        onChangeValue={handleChangeValue}
                                        req
                                        labelTxt={t("barItems.name")}
                                        placeholderTxt={t("barItems.namePlaceholder")}
                                        name="name"
                                        id="barItemName"
                                    />
                                </Col>

                                <Col xs={12} lg={7} md={10}>
                                    <Form.Label>
                                        {t("barItemCategories.priority")} <span className="required">*</span>
                                    </Form.Label>
                                    <p className="text-small mb-3">{t("barItems.priorityHelper")}</p>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Form.Control
                                                type="number"
                                                placeholder={t("barItemCategories.priorityPlaceholder")}
                                                name="priority"
                                                id="barItemPriority"
                                                value={barItem.priority}
                                                onChange={handleChangeValue}
                                                className="req"
                                                size="lg"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} lg={5}>
                                    <Checkbox
                                        className="mb-5 mt-2"
                                        id="barItemActivity"
                                        label={t("barItems.activity")}
                                        onClick={(e, data) => {
                                            setBarItem({ ...barItem, active: data.checked });
                                            document.getElementById("barItemActivity").classList.remove("is-invalid");
                                            setDisableSubmitting(false);
                                        }}
                                        checked={barItem.active}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} xs={12}>
                                    <Form.Label>{t("barItems.price")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder={t("barItems.pricePlaceholder")}
                                            aria-label="barItemPrice"
                                            aria-describedby="barItemPrice"
                                            size="lg"
                                            name="price"
                                            id="barItemPrice"
                                            onChange={handleChangeValue}
                                            value={barItem.price}
                                        />
                                        <InputGroup.Text id="barItemPrice">{t("currency")}</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <Form.Label>{t("barItems.innerPrice")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder={t("barItems.pricePlaceholder")}
                                            aria-label="barItemInnerPrice"
                                            aria-describedby="barItemInnerPrice"
                                            size="lg"
                                            name="innerPrice"
                                            id="barItemInnerPrice"
                                            onChange={handleChangeValue}
                                            value={barItem.innerPrice}
                                        />
                                        <InputGroup.Text id="barItemInnerPrice">{t("currency")}</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <Form.Label>{t("barItems.ingamePrice")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder={t("barItems.pricePlaceholder")}
                                            aria-label="barItemIngamePrice"
                                            aria-describedby="barItemIngamePrice"
                                            size="lg"
                                            name="ingamePrice"
                                            id="barItemIngamePrice"
                                            onChange={handleChangeValue}
                                            value={barItem.ingamePrice}
                                        />
                                        <InputGroup.Text id="barItemIngamePrice">{t("caps")}</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <ChooseTileIcon
                                    icon={barItemIcon}
                                    id="barItemIcon"
                                    onChooseIcon={(file) => {
                                        //console.log(file);
                                        setBarItemIcon(file);
                                        setDisableSubmitting(false);
                                        setBarItem({ ...barItem, selectedIcon: true });
                                    }}
                                />
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit" disabled={disableSubmitting} size="lg" className="mt-4">
                                        {t("add")}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Form>
            )}
        </Fragment>
    );
};

export default AddBarItem;
